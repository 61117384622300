$primary: #DC9018;
$primary100: #d48a15;
$disabletxtbox: #F7F7F7;

$primaryDark: #d48a15;
$primaryLight: #DC9018;

$secondary: #425466;
$secondaryDark: #272f33;
$secondaryLight: #60696d;

$error: #ff413a;
$errorDark: #a20e00;
$errorLight: #ec4333;

$warning: #f4a100;
$warningDark: #aa7000;
$warningLight: #f6b333;

$info: #00cfd5;
$infoDark: #009095;
$infoLight: #33d8dd;

$success: #00ac69;
$successDark: #007849;
$successLight: #33bc87;

$grey300: #425466;
$dark: #343a40;

$bg100: #f8f8f9;

$bordercustomlight: #D0D0D0;
$bordercustomdark: #303030;

// Divider
$divider: #D9D9D9;
$dividerDark: #C0C0C0;

$borderColorLight: #C4C4C4;
$borderColorDark: #ffffff;

$tabColorLight: #ffffff;
$tabColorDark: #272727;

// Text Colors

//$textPrimary: #69707a;
//$textSecondary: #4a515b;
//$textDark: #212832;
//$textHint: #00000061;

$textPrimary: #242c3a;
$textSecondary: #1b212c;
$textDark: #12171e;
$textHint: #00000061;

// Text Dark Color
$textDarkPrimary: #fff;
$textDarkSecondary: #babfc9;
$textDarkDark: #fff;
$texDarkHint: #94919161;


// Background Color
$backgound: #F2F3F7;
$backgoundDark: #000000;

// Paper Color
$paper: #ffffff;
$paperDark: #272727;

// TableBorder Color
$tableBorderColorLight: #B0B1B1;
$tableBorderColorDark: #B0B1B1;

// Divider
$divider: #D9D9D9;
$dividerDark: #C0C0C0;

// Dividerr
$dividerr: #616161;
$dividerrDark: #ffffff;

// DownArrow
$downArrowLight: #242c3a;
$downArrowDark: #ffffff;


$menuHover: #fff;


// TableBorder Color
$tableLight: #DFDFDF;
$tableDark: #1C1C1C;

// TableBorder Color
$tableLastLight: #ffffff;
$tableLastDark: #272727;

// Message Box
$messageboxlight: #CCCCCC;
$messageboxdark: #727272;

// Message Box Text
$messageboxtextlight: #6E6E6E;
$messageboxtextdark: #fff;

// ChatSupport
$chatLight: #DBD9D9;
$chatDark: #727272;

// Home Page Light Bg 
$homeLight: #efeded;
$homeDark: #191919;

// Home Page Dark Bg 
$home1Light: #ffffff;
$home1Dark: #000000;

// Price Table Dark Bg 
$basicLight: #F5DEBA;
$basicDark: #EDB974;
$advLight: #C2D1B3;
$advDark: #B7DD8E;
$proLight: #B7C9D5;
$proDark: #B6C9D4;

// Help Center Link 
$helplinkLight: #efefef;
$helplinkDark: #191919;

// Help Center BTN 
$helpbtnLight: #2B2B2B;
$helpbtnDark: #424242;

// Cryptocurrency Table btn 
$buyLight: #29292D;
$buyDark: #6E6E6E;

// Blog bg  
$blogbgLight: #fff;
$blogbgDark: #191919;

// Menu bg  
$menubgLight: #E8E8E8;
$menubgDark: #191919;


// this will use in javascript
:export {
    primary: $primary;
    primaryDark: $primaryDark;
    primaryLight: $primaryLight;
    primary100: $primary100;

    secondary: $secondary;
    secondaryDark: $secondaryDark;
    secondaryDark: $secondaryDark;

    error: $error;
    errorDark: $errorDark;
    errorLight: $errorLight;

    warning: $warning;
    warningDark: $warningDark;
    warningLight: $warningLight;

    info: $info;
    infoDark: $infoDark;
    infoLight: $infoLight;

    success: $success;
    successDark: $successDark;
    successLight: $successLight;

    grey300: $grey300;

    bg100: $bg100;

    // Text Colors
    textPrimary: $textPrimary;
    textSecondary: $textSecondary;
    textDark: $textDark;
    textHint: $textHint;

    // Text Dark Colors
    textDarkPrimary: $textDarkPrimary;
    textDarkSecondary: $textDarkSecondary;
    textDarkDark: $textDarkDark;
    textDarkHint: $texDarkHint;

    // Background Color
    backgound: $backgound;
    backgoundDark: $backgoundDark;

    // Paper Color
    paper: $paper;
    paperDark: $paperDark;
    menuHover: $menuHover;

    // Divider
    divider: $divider;
    dividerDark: $dividerDark;

    // Dividernew
    dividerr: $dividerr;
    dividerrDark: $dividerrDark;

    // Border Design
    borderColorLight: $borderColorLight;
    borderColorDark: $borderColorDark;

    // Tab Design
    tabColorLight: $tabColorLight;
    tabColorDark: $tabColorDark;
	
	// TableBorder Color
     tableBorderColorLight: $tableBorderColorLight;
     tableBorderColorDark: $tableBorderColorDark;

     // DownArrow Color
     downArrowLight: $downArrowLight;
     downArrowDark: $downArrowDark;

         // TableBorder Color
    tableBorderColorLight: $tableBorderColorLight;
    tableBorderColorDark: $tableBorderColorDark;

    // TableBorder Color
    tableLight: $tableLight;
    tableDark: $tableDark;

    // TableBorder Color
    tableLastLight: $tableLastLight;
    tableLastDark: $tableLastDark;

    // Message Box
    messageboxlight: $messageboxlight;
    messageboxdark: $messageboxdark;

    // Message Box Text
    messageboxtextlight: $messageboxtextlight;
    messageboxtextdark: $messageboxtextdark;

    // ChatSupport
    chatLight: $chatLight;
    chatDark: $chatDark;


    // Home Page Light Bg
    homeLight: $homeLight;
    homeDark: $homeDark;

    // Home Page Dark Bg
    home1Light: $home1Light;
    home1Dark: $home1Dark;

    // Price Table Dark Bg 
    basicLight: $basicLight;
    basicDark: $basicDark;
    advLight: $advLight;
    advDark: $advDark;
    proLight: $proLight;
    proDark: $proDark;

    // Help Center Link
    helplinkLight: $helplinkLight;
    helplinkDark: $helplinkDark;

    // Help Center Link
    helplinkLight: $helplinkLight;
    helplinkDark: $helplinkDark;

    // Help Center btn
    helpbtnLight: $helpbtnLight;
    helpbtnDark: $helpbtnDark;

    // Cryptocurrency Table btn 
    buyLight: $buyLight;
    buyDark: $buyDark;

    // Cryptocurrency Table btn 
    blogbgLight: $blogbgLight;
    blogbgDark: $blogbgDark;

    // Cryptocurrency Table btn 
    menubgLight: $menubgLight;
    menubgDark: $menubgDark;


}

    

