@import 'themes-vars';
@import 'plugins/plugins';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@font-face {
    font-family: 'geomanistregular';
    src: url('../fonts/geomanist-regular.woff2') format('woff2'),
         url('../fonts/geomanist-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'geomanistregular_italic';
    src: url('../fonts/geomanist-regular-italic.woff2') format('woff2'),
         url('../fonts/geomanist-regular-italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

body, .MuiTypography-body1, .MuiButton-root, .MuiTypography-h1, .MuiTypography-h2, .MuiTypography-h3, .MuiTypography-h4, .MuiTypography-h5, .MuiTypography-h6, .MuiTableCell-root { font-family: 'geomanistregular' !important; }
.icon-breadcrumb { margin-right: 5px; }
.link-breadcrumb { font-size: 0.75rem; display: flex; text-decoration: none; }

.BtmText .MuiTypography-body1 { font-family: 'Roboto', sans-serif !important; }
/* card */
body { overflow-x: hidden; }
.card-header { font-weight: 600 !important; font-size: 15px; }
.apexcharts-legend-marker { margin-right: 8px !important; }
.right-button.f-righ + h3 + hr { margin-top: 26px !important; margin-bottom: 10px; }
.MuiTablePagination-root:last-child { border-bottom: none !important;}
.btn-primary { color: #fff !important; background-color: $primary !important; width: 100%; margin-top: 10px !important; box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); padding: 6px 16px; font-size: 1.075rem; }
.userbox { margin-top: 5px; padding: 25px 0px; border-radius: 8px;}
.userbox .cstm-select { padding: 0 10px; }
.userbox img { margin: 0 auto; float: none; display: table; border-radius: 50%; }
.MuiSelect-icon { right: 7px !important; top: calc(50% - 9px) !important; }
.title-box-expire { padding: 0 14px; margin-bottom: -12px !important; float: left; width: 100%; }
.progress-status { width: 100%; height: 5px; background: #CCC; float: left; margin-top: 10px; margin-bottom: 15px; border-radius: 3px; overflow: hidden; position: relative; margin-bottom: 10px;}
.fillbox { position: absolute; height: 5px; }
.password-strength { float: right; font-weight: 600; font-size: 13px; margin-top: 9px; margin-bottom: -5px; }
.stepbox { margin-bottom: 25px !important; margin-top: 15px !important; }
.stepbox .fill.active { color: #2A323C; }
.stepbox .fill { background: #CCCCCC; border-radius: 50%; margin-top: -10px; margin-right: 13px; color: #000; text-align: center; font-weight: 600; line-height: 37px;  width: 37px; height: 37px; float: left; }
.stepbox .active .fill { background: #DC9018; color: #fff; }
.stepbox .active { color: #DC9018; }
.stepbox .active .active-border { background: #DC9018; width: 35px; height: 3px; float: right; margin-top: 9px; }
.stepbox .active-border { background: #CCCCCC; width: 35px; height: 3px; float: right; margin-top: 9px; }
.stepbox .active.active-form .fill { border: 1px solid #DC9018; background: #fff; color: #DC9018; }
.stepbox .active.active-form .fill + .active-border { background: #CCCCCC; }
.strength-text { font-size: 15px; color: #808080; margin-top: 9px; float: left; width: 100%; }
.cstm-select { margin-top: 16px !important; margin-bottom: 8px !important;}
.link-btn { color: #fff !important; background-color: $primary !important; width: 100% !important; float: left; padding: 6px 16px; font-weight: 500; line-height: 1.75; border-radius: 4px; text-align: center; font-size: 1.075rem; margin-top: 10px !important; box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); text-decoration: none; }
.link-btn-new { color: #fff !important; background-color: $primary !important; float: left; width: auto; padding: 6px 16px; font-weight: 500; line-height: 1.75; border-radius: 4px; text-align: center; font-size: 1.075rem; margin-top: 10px !important; box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); text-decoration: none; }
.link-btn-default { padding: 6px 16px; font-weight: 500; line-height: 1.75; min-width: 64px; box-sizing: border-box; font-weight: 500; border-radius: 4px; float: left; margin-top: 14px !important; text-transform: uppercase; text-decoration: none; }
.bordertop { border-top: 2px solid $primary; padding: 15px;}
.error-title { margin-top: 10px !important; font-size: 22px !important; color: $error !important; }
.modal-header{ background: $error; }
.modal-title h2 { font-size: 17px !important; color: #fff; }
.btn-danger { background: $error !important; color: #fff !important;}
.card-title-box img { margin: 0 auto; display: table; max-height: 45px; }
.modal-footer-bottom { padding: 15px 24px 20px 23px; border-top: 1px solid #EFEFEF; }
.textarea-acount { border: 1px solid #C4C4C4; border-radius: 5px; background: transparent; padding: 18.5px 14px; font-size: 13px; height: auto !important; }
.acount-number { font-size: 16px; margin-bottom: 8px; margin-top: 5px; }
.eur-main, .eur-checking { opacity: 0.4; float: left; width: 100%; }
.MuiSelect-select .acount-number { margin-bottom: 10px; }
.MuiSelect-select .eur-main, .MuiSelect-select .eur-checking { margin-top: 4px; }
.current_balance, .account_balance { font-size: 16px !important; margin-top: 0 !important; margin-bottom: 10px !important; } 
.account_balance-cstm { font-size: 16px !important; margin-top: 17px !important; margin-bottom: 0px !important; }
.account_balance-cstm-date { font-size: 16px !important; margin-top: 17px !important; margin-bottom: -14px !important;  }
.spacebox { padding: 15px;  border: 1px solid $tableBorderColorDark; float: left; width: 100%;  margin-top: 16px; border-radius: 5px; min-height: 114px;}
.current_price, .current_available { opacity: 0.4; margin: 0; }
.MuiFormControl-root.cstm-select.min-height .MuiOutlinedInput-notchedOutline { min-height: 120px; }
.bdr { border-bottom: 1px solid rgba(0,0,0,0.05); padding: 12px 0; font-size: 14px; }
.bdr_none { padding: 12px 0; font-size: 14px; }
.border0 { border: none !important;}
.text-primary { color: $primary; }
.valuetype { opacity: 0.7; font-size: 15px; }
.MuiGrid-root.value { font-size: 15px; }
// .MuiOutlinedInput-notchedOutline { border: transparent; }
.news-date { opacity: 0.5; margin-top: 8px !important; margin-bottom: 5px !important; }
.MuiButton-text.news-link { background: #DC9018; color: #fff !important; text-transform: capitalize; padding: 4px 14px; font-size: 13px; margin-bottom: 19px !important; margin-left: 0px !important; }
.MuiButton-text.news-link:hover { background: #DC9018; }
.news-title { font-size: 21px !important; }
.profile-icon { float: left; font-size: 62px !important; margin-top: 0px; padding-bottom: 10px; border-bottom: 1px solid #D9D9D9; padding-right: 19px; }
.exchange-icon { float: left; font-size: 59px !important; margin-top: 2px; padding-bottom: 14px; border-bottom: 1px solid #D9D9D9; padding-right: 19px; }
.news { font-size: 15px; line-height: 24px; margin-bottom: 10px !important; }
.news-link { margin: 5px 0px 11px -7px !important; color: $primary !important;  } 
.responsive-img img { width: 100% !important;}
.list-type { float: left; width: 100%; padding: 8px 0px 5px; font-size: 16px; }
.list-type a { padding: 10px 15px; text-decoration: none; margin-right: 7px; margin-bottom: 10px; font-weight: 400; border-radius: 7px; line-height: 1.643em; letter-spacing: 0em; text-transform: capitalize; font-size: 16px;}
.list-type a.active { box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.12), 0 2px 2px -1px rgba(0, 0, 0, 0.05); color: $primary; border-bottom: 2px solid $primary; text-decoration: none; cursor: pointer; }
.list-type .MuiButton-root:hover { color: $primary; background: transparent; }
.files_table .MuiIconButton-label { margin-left: 0px !important; }
.MuiTypography-root.Authenticator-title { font-size: 25px; font-weight: 600; margin-bottom: 6px; }
.debit-account { font-size: 18px !important; margin-top: 6px !important; margin-bottom: 9px !important; }
.border-top .MuiPaper-elevation1.MuiPaper-rounded { border-top: 2px solid $primary; }
.MuiTypography-root.MuiLink-root.MuiLink-underlineHover.makeStyles-btnlink-61:hover { text-decoration: none; }
.debit-text { opacity: 0.4; }
.MuiGrid-root.list-type.float-left { float: left; width: auto; }
.MuiGrid-root.us-info { float: left; width: 100%; border-bottom: 1px solid rgba(211, 213, 218, 0.4); padding-bottom: 10px; }
.MuiGrid-root.us-info svg { font-size: 55px; float: left; }
.MuiGrid-root.main_user { float: left; padding-left: 13px; }
.MuiTypography-root.ad-name { font-size: 17px; font-weight: 500; }
.MuiTypography-root.ad-time { font-size: 12px; opacity: 0.7; font-weight: 500; }
.MuiTypography-root.message-text { margin-top: 0; font-size: 15px; float: left; width: 100%; padding: 15px; border-radius: 8px; }
.MuiGrid-root.left-side { float: left; width: 100%; margin-bottom: 25px; }
.MuiGrid-root.right-side .MuiGrid-root.us-info { float: right; text-align: right;  }
.MuiGrid-root.right-side .MuiGrid-root.us-info svg { float: right; color: $primary;}
.MuiGrid-root.right-side .MuiGrid-root.main_user { float: right; padding-right: 13px; padding-left: 0px; }
.MuiGrid-root.min-space { float: left; width: 100%; min-height: 360px; }
.MuiGrid-root.right-side .MuiGrid-root.us-info:last-child { border: none; }
.MuiTypography-root.title { font-size: 18px; margin-bottom: 10px; font-weight: 500; }
.link-header { color: #1C1303 !important;}
.whitebox-center { margin: 25px auto 0;}
.min-box { width: 100%; padding: 10px;}
.MuiGrid-root.progresscstm { position: fixed; background: #FFF; width: 100%; height: 100%; z-index: 111; }
.MuiGrid-root.progresscstm .MuiCircularProgress-root { position: absolute; margin: auto; left: 0; right: 0; top: 0; bottom: 0; width: 65px !important; height: 65px !important; }
.MuiGrid-root.progresscstm-dark { background: #000 !important; }
.Verifybtn-cstm .MuiButtonBase-root { margin-top: 6px; margin-left: 15px; padding: 15px 20px; }
.Verifybtn-cstm .MuiButtonBase-root:hover, .Verifybtn-cstm .MuiButtonBase-root:focus { background: #DC9018;}

.makeStyles-main-17 > div { transform: none !important; opacity: 1 !important; }
.MuiGrid-root.rerer { transform: none !important; position: fixed; top: 0; z-index: 111111; }
.MuiAlert-action .MuiIconButton-label { margin-left: 0; }
button.MuiButton-root:hover { background-color: #DC9018; }
.MuiGrid-root.cstm-tabs .MuiAppBar-root { background: transparent; box-shadow: none; }
.MuiGrid-root.cstm-tabs .MuiBox-root { padding-left: 0; padding-right: 0; float: left; width: 100%; margin-bottom: 15px; }
.MuiGrid-root.cstm-tabs .MuiTab-root { min-width: auto; padding: 8px 28px; }
.MuiGrid-root.cstm-tabs .MuiTab-root.Mui-selected { background-color: $primaryDark; color: #fff; border-radius: 5px !important;}
.MuiGrid-root.cstm-tabs .MuiTabs-indicator { background: transparent; }
.pt-0-pb-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
.space-remove { padding: 0 15px; }
.maxlength { margin: 40px auto 15px; display: table; width: auto !important; }
.maxlength .styles_react-code-input__CRulA > input { margin: 0 4px; border: 1px solid #C4C4C4 !important; border-radius: 4px !important; width: calc(15% - 1px) !important; height: 58px !important; font-weight: 600; }
.lastchild-border-remove .MuiGrid-root:last-child hr { display: none; }
.button-space-height a { width: 100%; min-height: 98px; }
.MuiSelect-outlined.MuiSelect-outlined .eur-checking { display: none; }
.frappe-chart{  padding-top: 7px; }
.frappe-chart, text.title {  font-size: 25px; font-weight: 600; }
.graph-svg-tip { display: none; }

.MuiCard-root {
	&.bg-primary {
		background-color: $primary;
		color: #fff;
		.MuiCardHeader-root {
			background-color: $primary;
			color: #fff;
		}
	}
	&.bg-secondary {
		background-color: $secondary;
		color: #fff;
		.MuiCardHeader-root {
			background-color: $secondary;
			color: #fff;
		}
	}
	&.bg-error {
		background-color: $error;
		color: #fff;
		.MuiCardHeader-root {
			background-color: $error;
			color: #fff;
		}
	}
	&.bg-warning {
		background-color: $warning;
		color: #fff;
		.MuiCardHeader-root {
			background-color: $warning;
			color: #fff;
		}
	}
	&.bg-info {
		background-color: $info;
		color: #fff;
		.MuiCardHeader-root {
			background-color: $info;
			color: #fff;
		}
	}
	&.bg-success {
		background-color: $success;
		color: #fff;
		.MuiCardHeader-root {
			background-color: $success;
			color: #fff;
		}
	}
	&.bg-dark {
		background-color: $dark;
		color: #fff;
		.MuiCardHeader-root {
			background-color: $dark;
			color: #fff;
		}
	}
	&.outline-primary {
		border: 1px solid + $primary;
		.MuiCardHeader-root {
			border-bottom: 1px solid + $primary;
		}
	}
	&.outline-secondary {
		border: 1px solid $secondary;
		.MuiCardHeader-root {
			border-bottom: 1px solid + $secondary;
		}
	}
	&.outline-error {
		border: 1px solid $error;
		.MuiCardHeader-root {
			border-bottom: 1px solid + $error;
		}
	}
	&.outline-warning {
		border: 1px solid $warning;
		.MuiCardHeader-root {
			border-bottom: 1px solid + $warning;
		}
	}
	&.outline-info {
		border: 1px solid $info;
		.MuiCardHeader-root {
			border-bottom: 1px solid + $info;
		}
	}
	&.outline-success {
		border: 1px solid $success;
		.MuiCardHeader-root {
			border-bottom: 1px solid + $success;
		}
	}
	&.outline-dark {
		border: 1px solid $dark;
		.MuiCardHeader-root {
			border-bottom: 1px solid + $dark;
		}
	}
}
// .hide-scroll { overflow-x: hidden; }
.btn-submit { max-width: 530px; margin: 20px auto 20px !important; padding: 10px 25px !important; }
.MuiFormLabel-root.Mui-disabled { color: #A9A9A9 !important }
.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline { border-color: #c6c6c6 !important ;}
.MuiButton-text.tr-bg:hover, .MuiButton-text.tr-bg:focus { background: transparent; text-decoration: underline; }
.p-20 { padding: 20px; }
.MuiButton-root.link-btn { padding: 6px 16px; font-size: 1.075rem; min-width: 64px; box-sizing: border-box; font-weight: 500; line-height: 1.75; border-radius: 4px; text-transform: uppercase; text-decoration: none; }
.MuiButton-root.link-btn-mod { width: auto !important; margin-left: -17px !important;}
.chechbox-size{ margin-left: -11px !important; margin-top: 10px !important; }
.view-btm-card { float: left; width: 100%; padding: 15px 0; }
.tablink { float: left; width: 100%; border-bottom: 1px solid rgba(0, 0, 0, 0.12); margin-bottom: 15px;  }
.tab-position { position: relative; z-index: 11;}
.tablink a { padding: 15px 15px; float: left; text-decoration: none; font-weight: 200; border-radius: 0; text-transform: capitalize;}
.link-default { text-decoration: none; margin-top: 10px; float: left;}
.text-mute { color: $secondaryLight;}
.tablink a.active { border-bottom: 2px solid $primary; color: $primary; }
.view-btm-card{ border-bottom: 1px solid rgba(0, 0, 0, 0.12);}
.MuiTableCell-root { padding-top: 15px !important; padding-bottom: 15px !important; border-bottom: 1px solid #f2f2f2 !important; }
// .MuiIconButton-label { margin-left: 15px; }
.full-width { float: left; width: 100%; margin-top: 25px !important; margin-bottom: 15px !important;}
.MuiSwitch-thumb { margin-left: 0px !important; }
.MuiSwitch-track { background-color: #756d6d !important; }
.card-title { font-size: 15px; opacity: 0.5; margin-bottom: 3px; }  
.select-box { margin-top: 16px !important; margin-bottom: 8px !important; }
.btn.btn-primary.m-0 { margin: 0 !important; }
.pull-right { float: right; }
.pull-left { float: left; }
.mt-5 { margin-top: 50px !important;}
.mt-4 { margin-top: 40px !important;}
.mt-3 { margin-top: 30px !important;}
.mt-2 { margin-top: 20px !important;}
.mt-1 { margin-bottom: 10px !important;}
.mb-5 { margin-bottom: 50px !important;}
.mb-4 { margin-bottom: 40px !important;}
.mb-3 { margin-bottom: 30px !important;}
.mb-2 { margin-bottom: 20px !important;}
.mb-1 { margin-bottom: 10px !important;}
.ml-3 { margin-left: 30px !important;}
.pt-0 { padding-top: 0px !important; }
.pb-0 { padding-bottom: 0px !important; }
hr.MuiDivider-root { background: $divider !important;}

.m-0.setcard { float: left; width: 100%; margin-top: 25px !important; margin-bottom: -10px !important; }
.f-right, .right-button.f-righ { float: right; }
.p-0 { padding: 0 !important; }
.m-0 { margin: 0 !important; }
.p-20 { padding: 20px !important; }

.responsive-table-card {
	.MuiTableCell-root {
		white-space: unset;
	}
}



.scrollbar-container { padding: 10px 15px !important;}
.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover { border-radius: 10px !important;}
div.MuiListItem-root.Mui-selected, div.MuiListItem-root.Mui-selected:hover { border-radius: 10px 10px 0 0 !important;}
.MuiListItem-root.Mui-selected { border-radius: 10px 10px 0 0; }
.MuiListItem-root:hover { border-radius: 10px !important; }
.MuiListItem-root.Mui-selected:hover { border-radius: 10px 10px 0 0 !important; }
.MuiListItem-root.Mui-selected + .MuiCollapse-container { border-radius: 0 0 10px 10px !important; overflow: hidden; }
.MuiListItem-root.Mui-selected + .MuiCollapse-container .MuiListItem-root { padding-left: 20px !important; color: #fff !important; }
.MuiListItem-root.Mui-selected + .MuiCollapse-container .MuiListItem-root:hover { border-radius: 0px !important; text-decoration: underline; }
.MuiCollapse-wrapperInner .MuiList-root { padding: 4px 0; background: #DC9018; }
.MuiDrawer-paperAnchorDockedLeft { box-shadow: none !important; }
.MuiListItem-root img { width: 38px; margin-left: 6px; box-shadow: 2px 1px 12px rgba(0, 0, 0, 0.2);height: 25px;}
.MuiCollapse-wrapperInner .MuiListItem-root img { background: transparent !important; box-shadow: none; box-shadow: none; width: 12px; height: 12px; margin-right: 10px; }
.MuiButtonBase-root.MuiListItem-button { padding-left: 10px !important; }

p.MuiTypography-root.lable-space { font-size: 15px; margin-bottom: -5px; font-weight: 600; }
.mb-0 { margin-bottom: 0px !important; }
.MuiGrid-root.Accountbox { text-align: center; padding: 5px 15px; font-size: 24px; position: relative; }
.MuiTypography-root.a-title { font-size: 19px; font-weight: 600 }
.MuiGrid-root.cardinfo { padding: 5px 15px; text-align: center; position: relative; }
.MuiTypography-root.cardnumbertitle { font-size: 14px; }
.MuiTypography-root.cardnumber { font-size: 21px; font-weight: 800; }
.font-100 { font-weight: 100 !important;}
.Accountbox .MuiTypography-root.cardbalance { font-size: 14px; }
.MuiTypography-root.cardstastu { font-size: 21px; font-weight: 700; }
.MuiGrid-root.listbox { text-align: center; margin-top: 5px; }
.MuiGrid-root.listbox .MuiTypography-root { font-size: 15px; padding: 5px 0; font-weight: 600; }
.MuiGrid-root.listbox .MuiTypography-root:hover,
.MuiGrid-root.listbox .MuiTypography-root.active { background: $primary; color: #000; cursor: pointer; }
img.arrow { max-width: 13px; display: inline-block; margin-left: 7px; margin-right: 0px; }
.MuiTableCell-root { border: none !important;}
.MuiTypography-root.tocketno { float: left; font-size: 17px; }
.MuiTypography-root.tocketno { float: left; font-size: 17px; }
.MuiTypography-root.tocketno span { float: left;}
.MuiTypography-root.tocketno + .tno { font-size: 17px; float: left; margin-top: 3px; margin-left: 10px; margin-right: 10px; font-weight: 600; }
.MuiTypography-root.tocketno + .tno + .tusername { font-size: 14px; margin-top: 2px; float: left; opacity: 0.6; }
.MuiTypography-root.tocketbank { float: right; font-size: 17px; font-weight: 500; }
.bdr-right { border-right: 1px solid #A1A1A1; }
.MuiBadge-root.notification { margin-left: 7px; }
.MuiBadge-root.notification .MuiSvgIcon-root { font-size: 27px; }
.MuiBadge-root.notification .MuiBadge-badge { right: 3px; }
.MuiGrid-root.cstm-news-box .MuiPaper-root { padding: 10px 15px !important; }
.MuiGrid-root.message p { font-size: 14px; margin-top: 12px; margin-bottom: 12px; }
.MuiGrid-root.btm-space-column .MuiTypography-root { margin-top: 10px; text-align: center; border-right: 1px solid #A1A1A1; }
.MuiGrid-root.cstm-news-box { margin-bottom: 30px; }
.tablink.demo-tab { border: none; }
.tablink.demo-tab a { background: no-repeat; padding: 10px 0; margin-right: 25px; }
.MuiGrid-root.btm-space-column a { color: $primary; text-decoration: none; font-weight: 500; margin: 0 auto; display: table; }
.MuiGrid-root.viewmessagebox { background: $primary; margin-bottom: 20px; }
.MuiButton-label { text-transform: capitalize; }
.MuiButton-text.news-link { background: #DC9018 !important; color: #fff !important; margin-left: 0px !important; padding: 2px 6px; }
.logo .MuiSvgIcon-root { color: #fff; }
.MuiTypography-root.plus-value { font-size: 21px; font-weight: 600; color: rgb(28, 143, 62); }
.MuiTypography-root.minus-value.plus-value { color: #FB2E4D; }
.ml-0 { margin-left: 0 !important; }

/* Home Page */
.MuiGrid-root.menu-link link { border-bottom: 2px solid transparent; border-radius: 0; font-size: 15px; padding: 6px 0; font-weight: 600; margin: 0 10px; }
img.map-img { width: 100%; margin-top: 50px; }
.MuiGrid-root.menu-link link:hover, .MuiGrid-root.menu-link link:focus { color: #DC9018;}
.MuiGrid-root.pull-right.top-space { margin-top: 16px; }
.MuiTypography-root.slider-main-title.text-uppercase { text-transform: uppercase; margin-bottom: 25px; }
.MuiTypography-root.slider-main-title { font-size: 49px; line-height: 65px; font-weight: 800; word-break: keep-all; }
.MuiTypography-root.slider-inner-title { font-size: 17px; max-width: 380px; color: #487B90; }
.get-started-btn { background: #3C1360; color: #fff; text-decoration: none; padding: 13px 30px; float: left; margin-top: 24px; font-size: 17px; border-radius: 61px; }

.MuiTypography-root.main-title-full { text-align: center; font-size: 42px; line-height: 65px; font-weight: 700; }
.get-started-btn.center-btn { margin: 25px auto 0; display: table; float: none; }
.main-title-full.text-left.ab-title { font-size: 42px; text-align: left; font-weight: 700;  margin-bottom: 40px; }
.MuiGrid-root.Personal-account-box { border-radius: 10px; background: #fff; float: left; width: 100%; box-shadow: 0px 5px 15px #d2d2d2; overflow: hidden; margin-top: 35px; }
.MuiGrid-root.Personal-account-box .MuiTypography-root.plan-title { text-align: center; background: #DC9018; padding: 4px 5px; font-weight: 500; font-size: 30px; color: #fff; }
.MuiGrid-root.logo-item img { margin: 28px auto 15px; display: table; padding-bottom: 20px; }
.MuiTypography-root.plan-type { text-align: center; font-weight: 600; font-size: 19px; color: #22201E; margin-bottom: 0; }
p.MuiTypography-root.price-small-text { color: #242c3a !important; margin: -19px auto 15px; display: table; border-bottom: 1px solid #DBDBDB; font-weight: 600; padding-bottom: 2px; text-transform: uppercase; }
.MuiGrid-root.Personal-account-box .get-started-btn { margin: 25px auto 16px; display: table; float: none; font-size: 15px; padding: 8px 35px; font-weight: 600; }
.MuiGrid-root.Personal-account-box.Advanced .MuiTypography-root.plan-title { background: #336600;}
.MuiGrid-root.Personal-account-box.Pro .MuiTypography-root.plan-title { background: #0C4B72;}
.user-shadow img {box-shadow: 7px 7px 9px 2px rgb(189 189 189 / 75%); }

// main > div + div { padding: 0 !important; }

.center-mobile { width: 100%; max-width: 270px; margin: 0 auto; display: table;}
.center-mobile-map { width: 100%; margin: 0 auto; display: table; margin-top: 25px; max-width: 820px; }
.center-mobile-big { width: 100%; max-width: 450px; margin: 0 auto; display: table;}
.align-center { align-items: center !important; display: flex; }
.MuiTypography-root.main-title-full-inner { font-size: 19px; margin-top: 16px; font-weight: 700; }
.img-box { width: 70px; float: left; }
.img-box .center-mobile { max-width: 58px; float: left; margin-right: 15px; margin-top: 6px; }
.MuiGrid-root.img-content-box { width: calc(100% - 70px); float: left; }
.MuiTypography-root.solution-title { font-size: 27px; font-weight: 600; }
.MuiTypography-root.solution-des {  font-size: 17px; opacity: 0.5; font-weight: 500; }

.MuiGrid-root.title-spance { margin-top: 50px; }
.MuiGrid-root.about-box { padding: 25px; border-radius: 15px; margin: 25px 16px; box-shadow: 7px 7px 9px 2px rgb(189 189 189 / 75%); background: #fff; }
.MuiFormControl-root.position-select { width: 150px; float: left; }
.transaction_check.MuiFormControl-fullWidth { width: calc(100% - 379px); float: left; margin-left: 20px; }
.MuiGrid-root.about-title .MuiTypography-root { text-align: center; font-size: 20px; font-weight: 600; margin-bottom: 14px; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; }
.MuiGrid-root.about-img img { width: 100%; max-width: 100%; }
.MuiGrid-root.about-des .MuiTypography-root { text-align: center; font-size: 17px; margin-top: 21px; opacity: 0.8; font-weight: 500; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; min-height: 85px; }
.MuiGrid-root.user-img img { width: 100%; max-width: 190px; margin: 0 auto; border-radius: 50%; float: none; display: table; }
.MuiTypography-root.main-user-name { text-align: center; font-size: 20px; font-weight: 600; margin-top: 19px; }
.MuiTypography-root.main-user-deg { text-align: center; font-size: 16px; }
.MuiGrid-root.company-user-details p { font-size: 16px; margin-bottom: 19px; line-height: 27px; font-weight: 500; }
.MuiGrid-root.btm10 { margin-bottom: 35px; }
.compant-logo { width: 100%; max-width: 200px; margin: 0 auto; display: table; }
.MuiGrid-root.partner-space { padding: 20px 0; }
.MuiButton-text.more:hover { background: $primary; }
.staticbg { background: #fff !important;}
.MuiTextField-root.staticbgnew input { background: #fff; color: #000; }
.MuiGrid-root.footer { padding: 70px 0 20px; float: left; width: 100%; }
.MuiTypography-root.footer-title { font-size: 18px; font-weight: 700; margin-bottom: 10px; }
.MuiGrid-root.link-footer a { float: left; width: 100%; font-weight: 500; text-decoration: none; font-size: 15px; margin-top: 6px; display: block; text-align: left; padding: 0; }
.MuiGrid-root.link-footer a:hover { background: transparent;}
img.nextarrow { max-width: 27px; float: left; margin-left: 9px; margin-top: -6px; }
.social-media-footer img { max-width: 30px; margin-right: 15px; }
.MuiGrid-root.social-media-footer { float: left; width: 100%; margin-top: 10px; }

.drp-eur .MuiFormControl-root.dropmenu-home, .drp-eur-black .MuiFormControl-root.dropmenu-home { float: right; margin-top: 20px; margin-bottom: -20px; }
.drp-eur .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, .drp-eur-black .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline { border: none;}
.drp-eur .MuiOutlinedInput-root .MuiSelect-icon { color: #000;}
.drp-eur select.MuiSelect-root.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input { font-weight: 600; font-size: 16px; border-bottom: 2px solid #000 !important; border-radius: 0;  }
.drp-eur-black select.MuiSelect-root.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input { border-bottom: 2px solid #fff; color: #fff; font-weight: 600; font-size: 16px; border-radius: 0; }
 
img.plan-bg { margin: 0 auto; display: table; float: none; }
.drp-eur-black .MuiOutlinedInput-root .MuiSelect-icon { color: #fff;}
.align-center.dark1 .MuiGrid-root.about-box { box-shadow: none;  background: #000000; }
.align-center.dark1 .MuiGrid-root.about-box .MuiGrid-root.about-des .MuiTypography-root	 { color: #6E6E6E; opacity: 1;}
.arrow-select-default { position: absolute; right: 0; top: 16px; }
.MuiGrid-root.social-media-footer .MuiButtonBase-root.MuiButton-root.MuiButton-text { float: left; width: auto; max-width: none !important; padding: 0px; }
.MuiTypography-root.copyright { font-weight: 600; font-size: 15px; }
.MuiButton-text.btn-user { border: 1px solid #2B2B2B; margin-right: 10px; padding: 1px 10px; font-size: 15px; }
.MuiButton-text.btn-user.active-btn, .MuiButton-text.btn-user:hover {  background: #DC9018; color: #fff; border-color: #DC9018; } 
.header-space { padding: 5px 0;}
.MuiGrid-root.light-mode, .MuiGrid-root.dark-mode { float: right; margin-top: 3px; margin-left: 10px; }
.MuiGrid-root.light-mode .MuiButtonBase-root, .MuiGrid-root.dark-mode .MuiButtonBase-root { padding: 0; background: transparent; min-width: auto; }
.MuiGrid-root.dark-mode .MuiSvgIcon-root { color: #fff; }
.MuiGrid-root.new-main-tab.firstbox-tabs .box-select svg, .MuiGrid-root.new-main-tab.secondbox-tabs .box-select svg { margin-top: -2px; margin-right: -3px; }

/* Black Tab */
.home-tabs.home-tabs-black .new-main-tab .MuiNativeSelect-select.MuiNativeSelect-select { color: #fff; }
.home-tabs.home-tabs-black .box-size input { background: rgb(15,15,15); background: linear-gradient(9deg, rgba(15,15,15,1) 0%, rgba(32,32,30,1) 100%); color: #fff !important; }
.home-tabs.home-tabs-black .MuiGrid-root.firstbox { border-color: #121213;}
.home-tabs.home-tabs-black .box-select { background: rgb(15,15,15); background: linear-gradient(180deg, rgba(15,15,15,1) 0%, rgba(32,32,30,1) 100%); }
.MuiGrid-root.home-tabs.home-tabs-black { background: #191919;  box-shadow: 0px 0px 0px 3px #262626; }
.MuiGrid-root.home-tabs svg.MuiSvgIcon-root.arrow-select-default { color: #C4C4C4; }
.price-box-shadow-none .MuiGrid-root.Personal-account-box { box-shadow: none; }
.home-tabs.home-tabs-black .MuiGrid-root.new-main-tab.firstbox-tabs input, .home-tabs.home-tabs-black .MuiGrid-root.new-main-tab.secondbox-tabs input { background: #121213; }
.home-tabs.home-tabs-black .MuiGrid-root.new-main-tab.sub.bg-dark-new-tabs input { background: #121213; }
.MuiGrid-root.title-upper-line { background: #F28A11; height: 9px; max-width: 390px; margin-bottom: 45px; width: 100%; }
.flag { width: 38px; height: 25px; }
.home-tabs.home-tabs-black span.MuiTypography-root.crypto-crypto-lable { background: #282828; color: #fff; }
.MuiButton-text.language, .MuiButton-text.language:hover { padding: 0px; background: transparent; margin-top: 2px; }
.MuiGrid-root.menu-link link.active { border-bottom: 2px solid #DC9018; border-radius: 0; color: $primary; }
.MuiGrid-root.menu-link a.active { border-bottom: 2px solid #DC9018; border-radius: 0; color: $primary; }
.MuiGrid-root.menu-link link:hover { background: transparent; border-bottom: 2px solid #DC9018; border-radius: 0; }
.flag-img .flag { margin: 0 14px;  border: 1px solid #A0A0A0; }

.static-height { min-height: 190px;}
.sumsub { margin: 0 auto; float: none; display: table; }
.slider-img { width: 100%; margin: 0 auto; display: table; max-width: 600px; }
.logo-item.accounts-plan img { max-width: 135px; width: 100%; }
.MuiTypography-root.level-account { font-size: 21px; font-weight: 500; margin-bottom: 15px; }
.MuiGrid-root.level-type { float: left; width: 100%; position: relative; margin-bottom: 8px; }
img.iconModal { position: absolute; right: 20%; bottom: 4px; width: 13px; cursor: pointer; }
.MuiGrid-root.level-type img { max-width: 100%; margin: 0px 0; }
img.level-img { width: 82%; float: left; }
img.level-check { width: 15%; margin-left: 3% !important; float: left; margin-top: 4px !important; }
.mobile-inner-pad.rmvspace { padding: 50px 0 0; margin-bottom: -7px; }
.MuiGrid-root.flwidth, a.MuiButton-text.flwidth { float: left; width: 100%; border-radius: 51px; }
.MuiGrid-root.flwidth:hover .MuiTypography-root.menu-text-main { color: $primary; }
// div { transform: initial !important; }
svg.MuiSvgIcon-root.iconModal { position: absolute; right: 19%; z-index: 1; bottom: 1px; cursor: pointer; color: #fff; font-size: 15px; }
.MuiSnackbar-root.fixbar-message { position: fixed; top: 80px; z-index: 111; }
img.country { float: left; width: 26px; margin-right: 12px; margin-top: -2px; }
.Modalcstm-bg .MuiDialog-paper { background: #DCDCDC; max-width: 575px; width: 100%;}
.Modalcstm-bg .MuiDialog-paper h2 { font-size: 21px; text-align: center; margin-bottom: -12px; margin-top: -4px; }
.Modalcstm-bg .MuiGrid-root.Limits-infi { background: #fff; text-align: center; box-shadow: 0 4px 6px -2px rgb(0 0 0 / 12%), 0 2px 2px -1px rgb(0 0 0 / 5%); }
svg.MuiSvgIcon-root.modal-close { cursor: pointer; position: absolute; right: 5px; font-size: 22px; top: 5px; }
.Modalcstm-bg .MuiGrid-root.Limits-box-inner { margin-bottom: 15px; }
.Modalcstm-bg.dark .MuiDialog-paper h2, .Modalcstm-bg.dark .MuiGrid-root.Limits-infi p, svg.MuiSvgIcon-root.modal-close { color: #000; }
img.center-mobile-big.small-size-online { max-width: 280px; }
img.center-mobile-big.small-size-online2 { max-width: 220px; }
img.center-mobile-big.small-size-online1 { max-width: 310px; }
.center-mobile.small-size-online2 { max-width: 400px; }
img.center-mobile-big.center-mobile-bighand { margin-bottom: -100px; max-width: 530px; }
.width90 { width: 90%; max-width: 100%; }
.youtube {  max-width: 69px !important; width: 69px !important; }
button.control-arrow.control-next, button.control-arrow.control-prev, p.carousel-status, .thumbs-wrapper.axis-vertical { display: none; }
.carousel.carousel-slider { padding-bottom: 40px; }
.carousel .control-dots .dot { background: #6E6E6E !important; width: 12px !important; height: 12px !important; box-shadow: none !important; margin: 0 5px !important; }
.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover { background-color: #3C135F !important;}
.MuiGrid-root.light-mode-new input.MuiInputBase-input.MuiOutlinedInput-input { background: #EAEAEA !important; }
.MuiGrid-root.dark-mode-new input.MuiInputBase-input.MuiOutlinedInput-input { background: #ECECEC !important; }
.center-mobile.min-height { min-height: 210px; }

/* Responsive Menu */
.MuiGrid-root.logo-box { width: 190px; margin-left: -3px; }
.MuiGrid-root.menu-space.MuiGrid-item { float: left; width: calc(100% - 590px); }
.MuiGrid-root.left-menu.MuiGrid-item { float: left; width: 270px; }
.MuiGrid-root.right-menu.MuiGrid-item { float: right; width: calc(100% - 270px) ; }
.MuiGrid-root.menu-link { margin: 12px auto 0; display: table; }
.MuiGrid-root.login-box.MuiGrid-item { order: 2; margin-left: auto; padding-right: 0px; }

.submenu .MuiMenu-paper { width: 100%; left: 0 !important; max-width: 100%; margin-top: 53px; border-radius: 0; }
.submenu .MuiMenu-paper .MuiMenu-list { max-width: 1250px; margin: 0 auto; display: table; width: 100%; padding: 15px 0; }

.MuiGrid-root.menu-img { width: 65px; float: left; }
.MuiGrid-root.menu-text { width: calc(100% - 65px);  float: left; margin-top: 5px; }
.MuiTypography-root.menu-text-main { font-size: 17px; font-weight: 500; line-height: 29px; }
.MuiTypography-root.menu-text-sub { font-size: 12px; opacity: 0.6; }
.MuiGrid-root.main-menu-box { padding: 8px; }

.MuiTypography-root.main-title { text-align: center; font-size: 45px; font-weight: 600; margin-top: -40px; margin-bottom: 25px; padding: 0 15px; }
.MuiTypography-root.terms-main-title { text-align: center; font-weight: 600; max-width: 720px; margin: 5px auto 20px; font-size: 16px; }
.MuiTypography-root.terms-main-des { font-weight: 500; line-height: 27px; margin-bottom: 25px; }
.order-2 { order: 2;}
a.MuiButtonBase-root.MuiButton-root.MuiButton-text.linkredirect { background: transparent; padding: 0; margin-top: 15px; font-weight: 700; color: $primary; font-size: 15px; }
.MuiTypography-root.infolink { font-size: 17px; font-weight: 500; }
.table-title .MuiTab-root { font-size: 28px; font-weight: 600;  border: none; border-radius: 15px; text-transform: capitalize; opacity: 1; padding: 0 20px; }
.table-title .Mui-selected { background: $primary; color: #fff; }
.MuiTabs-root.table-title .MuiTabs-flexContainer + span { display: none; }
.MuiTypography-root.table-title span { background: $primary; color: #fff;  padding: 5px 12px; margin-left: 16px; border-radius: 8px;}
.img-center-check { max-width: 23px; margin: 0 auto; display: table; }
.space-pad th, .space-pad td { padding: 0 !important; }
p.MuiTypography-root.table-title { font-size: 28px; font-weight: 600; margin-bottom: 25px; }
.opacity-none { opacity: 0; }
.logo-box img { max-width: 100%; }
// .react-swipeable-view-container { display: block !important; }
.MuiTypography-root.main-title.text-left { text-align: left; padding-left: 0; margin-bottom: 0; }
.MuiTypography-root.main-title-sub.text-left { font-size: 22px; font-weight: 600; max-width: 520px; margin: 20px 0px 30px 0; width: 100%; }
.MuiTypography-root.main-title-box { text-align: center; font-weight: 600; font-size: 24px; text-transform: uppercase; margin-bottom: 24px; }
.fullwidthnew { float: left; width: 100%; }
.cstm-pad { padding: 100px 20px !important; }
.MuiTypography-root.operator { max-width: 760px; text-align: center; margin: 10px auto 40px; font-weight: 600; font-size: 23px; }
.help_center_link { float: left; width: 100%; }
.help_center_link p { float: left; width: 100%; }
.help_center_link p a { text-decoration: none; }
.MuiTypography-root.address { font-size: 23px; line-height: 39px; margin-top: 15px; }
.MuiButton-text.get-direction { font-size: 22px; margin-left: -8px !important; margin-top: 20px; }
iframe { border: none; margin-top: -25px; }
.MuiTypography-root.main-title-sub { font-size: 18px; text-align: center; max-width: 420px; margin: 0 auto; display: table; font-weight: 500; min-height: 60px; }
.MuiTypography-root.main-title.main-title-sub-main { margin-bottom: 10px; }
.MuiTypography-root.main-title-sub.full { max-width: 100%; margin-bottom: 25px; }
.MuiTypography-root.slider-main-title.text-center.download-text { margin-top: 35px; text-align: center; font-size: 35px; }
.txt-center { text-align: center !important;}
.MuiGrid-root.storebox { margin: 0 auto; display: table; }
.MuiGrid-root.storebox img { max-width: 275px; margin: 15px 7px 0; }
.MuiTypography-root.question { margin-top: 15px; font-size: 25px; font-weight: 600; }
iframe.iframe-top { margin-top: 35px; margin-bottom: 25px; }
.MuiGrid-root.product { padding-top: 25px; background: #fff; padding: 15px; float: left; width: 100%; }
.MuiGrid-root.product-pic { border: 4px solid #F2F2F2; }
.MuiGrid-root.product-pic img { max-width: 100%; }
.MuiTypography-root.product-name { font-size: 17px; margin-top: 14px; font-weight: 500; color: #797C80; }
.MuiTypography-root.product-price { font-size: 17px; margin-top: 1px; font-weight: 500; color: #797C80; }
.MuiButton-text.load-more1 { padding: 14px 6px; background: #3C1360; color: #fff; border-radius: 4px; text-transform: uppercase; margin-left: 20px; min-width: 187px; }
.MuiButton-text.load-more { display: table; padding: 12px 50px; background: #3C1360; color: #fff; margin: 35px auto 20px; border-radius: 61px; text-transform: uppercase; }
.MuiButton-text.load-more:hover { background: #DC9018; }
.MuiTableContainer-root.simple-table tr { background: transparent; border-bottom: 2px solid #747474; }
.MuiTableContainer-root.simple-table .MuiTableCell-head { font-size: 18px; }
.MuiTableContainer-root.simple-table thead.MuiTableHead-root { background: transparent; }
.MuiTableContainer-root.simple-table td { font-size: 18px; }
.MuiTableContainer-root.simple-table img { width: 41px; float: left; }
td.MuiTableCell-root.MuiTableCell-body.text-left { text-align: left !important; padding-left: 0 !important; }
td.MuiTableCell-root.MuiTableCell-body.coin-tb-logo { width: 0px; }
.position-relative { position: relative; }
img.qr-position { max-width: 40px; position: absolute; right: 6px; margin-top: 7px; }
td.MuiTableCell-root.plus { color: #7C9548; }
td.MuiTableCell-root.minus { color: #B91818; }
.MuiTableContainer-root.simple-table { float: left; width: 100%; margin-top: 45px; }
td.MuiTableCell-root.text-right { width: 185px; }
button.btn-buy { color: #fff; }
button.MuiButton-text.btn-sell { background: #E96F16; color: #fff; margin-left: 5px; }
.MuiGrid-root.menu-img img { float: left; width: 100%; }
.MuiGrid-root.flwidth:hover, a.MuiButton-text.flwidth:hover { background: rgba(0,0,0,0.04); }
p.MuiTypography-root.main-title-sub.text-left.main-left { text-align: left; }
p.MuiTypography-root.main-title-sub.text-left.main-left.newcenter { margin: 20px auto 30px; text-align: center; }
.menu-link a.MuiButtonBase-root.MuiButton-text { font-weight: 600; border-bottom: 2px solid transparent; border-radius: 0; padding-left: 0px; padding-right: 0px; margin-left: 6px; font-size: 15px; }
.menu-link a.MuiButtonBase-root.MuiButton-text:hover { border-color: $primary; background: transparent; color: $primary; }
.MuiGrid-root.flwidth:hover, a.MuiButton-text.flwidth.active .MuiTypography-root.menu-text-main { color: $primary;}
.text-center-new { text-align: center !important;}
.social-media-footer.newMedia a img { width: 35px; }
p.MuiTypography-root.small-with-text { text-align: center; margin-top: -22px; margin-bottom: 25px; font-size: 13px; }
/**/

/* CSTM TAB */
.MuiGrid-root.tabfullWidth { float: left; width: 100%; }
.MuiGrid-root.money-select .MuiOutlinedInput-notchedOutline { border: none; }
.new-main-tab {z-index: 5; width: 100%; float: left; position: relative; }
.MuiGrid-root.money-select { float: left; position: relative; width: 190px; }
.MuiGrid-root.box-size { float: left; width: calc(100% - 190px); border-radius: 0 29px 29px 0; overflow: hidden; }
.MuiGrid-root.money-select .MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl { min-height: 45px; }
.box-size input { background: #FFFFFF; color: #000; font-size: 18px; font-weight: 400; min-height: 32px; width: 100%; /*margin-top: -2px;*/ }
.MuiGrid-root.box-size .MuiInputBase-root.MuiInput-root.MuiInput-underline { width: 100%; border: none; }
span.MuiTypography-root.crypto-crypto-lable { background: #C7C7C7; font-size: 14px; padding: 11px 13px; right: 0; position: absolute; font-weight: 600; text-transform: uppercase; }
.box-size input:hover, .box-size input:focus { border: none !important; }
// ul.main-tab li:hover:last-child {
// 	background: #DC9018; color: #FFFFFF;
// }
.MuiGrid-root.box-size.fullWidth.copyclipboardbox { position: relative; }
.MuiGrid-root.box-size.fullWidth.copyclipboardbox .MuiGrid-root.tooltip-box { position: absolute; right: 0; top: 12px; } 
.MuiGrid-root.firstbox { float: left; width: 100%; /* border: 1px solid #ebedfb; */ }
.MuiGrid-root.dark .MuiGrid-root.tooltip-box button { color: #fff; }
.MuiGrid-root.dark .MuiGrid-root.tooltip-box button:hover { color: $primary; }
.MuiGrid-root.new-main-tab.sub .MuiGrid-root.firstbox .MuiGrid-root.box-size { width: 100% }
.MuiGrid-root.new-main-tab.sub .MuiGrid-root.firstbox .MuiGrid-root.box-size .font-bold-textbox input { text-align: left; padding-left: 20px; padding-right: 20px; }
img.drparrow { position: absolute; z-index: 1; right: 3px; max-width: 24px; margin-top: 14px; pointer-events: none; }
.MuiGrid-root.new-main-tab.sub { box-shadow: none; z-index: 1; margin-top: -1px; }
.new-main-tab .MuiNativeSelect-select.MuiNativeSelect-select { color: #444; font-weight: 600; padding-left: 5px; min-height: 35px; padding: 10px 0 10px 13px; }
.MuiGrid-root.home-tabs:after { opacity: 0; content: ""; bottom: -22px; left: 0; width: 100%; height: 24px; right: 0; background: url('../images/promo-transaction-bottom.png') no-repeat center center; position: absolute; background-size: 93%; }
.MuiGrid-root.home-tabs.home-tabs-black:after { content: ""; bottom: -22px; left: 0; width: 100%; height: 24px; right: 0; background: url('../images/promo-transaction-bottom-dark.png') no-repeat center center; position: absolute; background-size: 93%; }
.MuiGrid-root.box-size.fullWidth { width: 100%; }
.MuiGrid-root.box-size.fullWidth input { min-height: 32px; font-size: 16px; color: #000; font-weight: 600; padding-left: 20px; padding-right: 20px; text-align: center; }
p.MuiTypography-root.email-typo { color: #444; font-weight: 600; margin-top: 13px; padding-left: 14px; font-size: 14px; }
p.MuiTypography-root.hover-text { display: none; position: absolute; }
.font-bold-textbox input { font-size: 16px; color: #000; font-weight: 600; }
.MuiGrid-root.box-size.cstm-box-select input { padding-left: 12px; }
.shadowboxlight .help_center_link p a, .shadowboxlight .help_center_link p button { background: transparent; box-shadow: 3px 5px 8px #d7d4d4; }
.shadowboxlight .help_center_link p a:hover, .shadowboxlight .help_center_link p button:hover { background: $primary; color: #fff; }
.help_center_link p a:hover, .help_center_link p button:hover { background: $primary; color: #fff; }
svg.MuiSvgIcon-root.help { color: #CCCCCC; position: absolute; right: 13px; width: 19px; margin-top: 12px; cursor: pointer; }
.new-main-tab .MuiInput-underline:hover:not(.Mui-disabled):before, .new-main-tab .MuiInput-underline:after, .new-main-tab .MuiInput-underline:before { border: none !important;}
.box-select { background: #eff1f6; position: relative; height: 100%; cursor: pointer; min-height: 45px; padding-left: 15px; width: 68px; border: none; color: #444; font-weight: 600; }
.box-select:focus-visible { box-shadow: none; outline: none;}
ul.main-tab { padding: 0; padding-bottom: 0; margin: 0 auto; display: table; float: none; }
.main-tab + .react-tabs__tab-panel { float: left; width: 100%; }
ul.main-tab li { font-weight: 600; cursor: pointer; list-style: none; float: left; font-size: 15px; margin: 0; padding: 13px 20px; border-radius: 25px; text-align: center; border: 2px solid #e3e3e3; margin: 0 5px;}
.MuiGrid-root.home-tabs { background: #F0EDEE; box-shadow: 0px 0px 0px 3px #DEDBDC; border: 23px solid #030303; border-radius: 25px; position: relative; display: table; max-width: 100%; margin: 40px auto; padding: 25px; }
.main-tab .react-tabs__tab--selected { background: $primary; color: #fff; }
.MuiGrid-root.line-dot { position: absolute; width: 13px; height: 13px; background: #8C8C8C; left: 0; right: 0; margin: auto; bottom: -17px; z-index: 11; border-radius: 50%; }
p.MuiTypography-root.card-title.MuiTypography-body1 { margin-bottom: 10px; float: left; width: 100%; opacity: 1; font-size: 27px; font-weight: 600; text-align: center; word-break: break-all; margin-top: 10px; }
.MuiGrid-root.inner-tab { float: left; width: 100%; margin-top: 5px; margin-bottom: 10px; }
.MuiGrid-root.inner-tab ul.react-tabs__tab-list { margin: 0 auto 15px; display: table; float: none; padding: 0; }
.MuiGrid-root.inner-tab ul.react-tabs__tab-list li { opacity: 0.8; font-weight: 700; cursor: pointer; list-style: none; float: left; font-size: 16px; margin: 0 5px; padding: 8px 15px; border-radius: 5px; text-align: center;}
.MuiGrid-root.inner-tab ul.react-tabs__tab-list li.react-tabs__tab--selected  { opacity: 1; border-bottom: 3px solid  $primary; border-radius: 0px;}
.MuiGrid-root.inner-tab ul.react-tabs__tab-list li:focus-visible, ul.main-tab li:focus-visible { outline: none; }
.MuiFormControl-root.dropmenu-home { width: 95px; }
.dropmbox-home.MuiFormControl-fullWidth { width: calc(100% - 98px); float: right; }
.MuiGrid-root.tab-info { float: left; width: 100%; margin: 2px 0; }
.MuiFormControl-root.dropmenu-full { width: 100%; }
p.MuiTypography-root.wallet-link { margin-top: 5px; float: left; width: 100%; text-align: center; }
button.MuiButton-text.get-started-btn { width: 100%; background: #3C1360; color: #fff; padding: 15px; font-size: 18px; font-weight: 500; margin-top: 11px; }
.radius-small { border-radius: 8px !important;}
.MuiGrid-root.map-styles { min-height: 500px !important; width: 100% !important; }
.MuiGrid-root.map-styles-home { padding-top: 50px; min-height: 500px !important; width: 100% !important; }
.exampleCarousel1 div > button[data-direction='left'] { background: url('../images/prev.png') no-repeat center; outline: none; border: none; cursor: pointer; position: relative; left: -20px; height: 80px; top: 42%; bottom: 0; margin: auto; width: 20px; }
.exampleCarousel1 div > button[data-direction='right'] { background: url('../images/nextt.png') no-repeat center; outline: none; border: none; cursor: pointer; position: relative; right: -5px; height: 80px; top: 42%; bottom: 0; margin: auto; width: 20px; }
.more.moreinfo { background: #29292D; display: table; padding: 12px 50px; margin: 35px auto 20px; border-radius: 61px; text-transform: uppercase; color: #fff; }

/* MAP CUSTOM */
.search-result { float: left; width: 100%; max-height: 350px; overflow: auto; }
.fullWidth-scroll { width: 100% !important; }
.fullWidth-scroll > div + div + div { background: #232322; width: 10px !important; right: 0px !important; }
.fullWidth-scroll > div + div + div div { background: #515151 !important; border-radius: 35px !important; }
.MuiGrid-root.search-map.search-map-bitcoin_atms.search-map-home { margin-top: 65px; }
.MuiGrid-root.search-map-bitcoin_atms { margin-top: 15px; }
.MuiGrid-root.search-map { max-width: 430px; position: absolute; z-index: 11; top: 20px; left: 18px; background: #232323; width: 100%; border-radius: 5px; overflow: hidden; }
.MuiGrid-root.p-relative { position: relative; }
.MuiGrid-root.search-map .MuiFormControl-root.MuiTextField-root, .MuiGrid-root.search-map .MuiOutlinedInput-root, .MuiGrid-root.search-map .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline { width: 100%; border: none !important; }
.MuiGrid-root.search-map .MuiInputBase-input {font-weight: 600; min-height: 35px; font-size: 17px; color: #fff; background: #000000; }
.MuiGrid-root.search-map button.MuiButtonBase-root.MuiButton-root.MuiButton-text { padding: 25px; background: #232323; border-radius: 0px; color: #fff; }
.MuiGrid-root.search-list { border-bottom: 1px solid #9B9B9B; padding: 15px; float: left; width: 100%; background: #191919; position: relative; }
p.MuiTypography-root.first-address { font-size: 16px; color: #fff; font-weight: 500; width: 100%; float: left; padding-left: 20px; }
p.MuiTypography-root.first-address svg { font-size: 18px; float: left; margin-top: 4px; position: absolute; left: 12px; top: 16px; }
p.MuiTypography-root.middle-address { float: left; width: 100%; font-size: 19px; color: #fff; font-weight: 500; margin: 3px 0; }
p.MuiTypography-root.last-address { float: left; width: 100%; margin-top: 3px; font-size: 14px; color: #fff; font-weight: 600; }
p.MuiTypography-root.last-address span { float: right; font-weight: 600; color: #fff; opacity: 0; }
p.MuiTypography-root.last-address span svg { margin-top: 1px; float: right; margin-left: 2px; }
.MuiGrid-root.search-list:hover p.MuiTypography-root.last-address span { opacity: 1;}
.MuiGrid-root.search-list:hover p.MuiTypography-root.last-address span:hover { color: #fff;}

/* Search Light Moda */
.MuiGrid-root.search-map.search-map-bitcoin_atms.search-light { background: #EFEDED; }
.MuiGrid-root.search-map.search-map-bitcoin_atms.search-light .MuiInputBase-input { background: #CDCBCB; color: #000; }
.MuiGrid-root.search-map.search-map-bitcoin_atms.search-light button.MuiButtonBase-root.MuiButton-root.MuiButton-text { background: #EFEDED; color: #000; }
.MuiGrid-root.search-map.search-map-bitcoin_atms.search-light .MuiGrid-root.search-list { background: #EFEDED; }
.MuiGrid-root.search-map.search-map-bitcoin_atms.search-light p.MuiTypography-root.first-address { color: #000; }
.MuiGrid-root.search-map.search-map-bitcoin_atms.search-light p.MuiTypography-root.middle-address { color: #000; }
.MuiGrid-root.search-map.search-map-bitcoin_atms.search-light p.MuiTypography-root.last-address { color: #000; }
.MuiGrid-root.search-map.search-map-bitcoin_atms.search-light p.MuiTypography-root.last-address span { color: #000; }
.MuiGrid-root.search-map.search-map-bitcoin_atms.search-light .MuiGrid-root.search-list:hover p.MuiTypography-root.last-address span:hover { color: #000; }
.MuiGrid-root.search-map.search-map-bitcoin_atms.search-light .fullWidth-scroll > div + div + div { background: #CDCBCB;}
.MuiGrid-root.search-map.search-map-bitcoin_atms.search-light .fullWidth-scroll > div + div + div div { background: #a5a3a3 !important; }

.MuiTypography-root.common-text { font-weight: 600; font-size: 16px; margin-bottom: 15px; }
.MuiTypography-root.common-text_new { font-weight: 700; font-size: 23px; margin-bottom: 15px; }
.MuiGrid-root.manage-info { float: left; width: 100%; margin: 10px 0; position: relative; padding-left: 23px; }
.MuiTypography-root.info1 { font-weight: 700; font-size: 16px; }
.MuiTypography-root.info1-list { font-weight: 500; font-size: 14px; }
span.MuiTypography-root.avalable-type { font-weight: 500; }
.MuiGrid-root.day-information { width: 100%; max-width: 260px; }
span.MuiTypography-root.day-list { font-weight: 500; font-size: 14px; width: 140px; float: left; }
span.MuiTypography-root.day-time { font-weight: 500; font-size: 14px; float: left; }
span.MuiTypography-root.day-list.day-list_small { width: 110px; }
.mt-7 { margin-top: 7px; }
.MuiGrid-root.day-information.day-information_new { max-width: 100%; float: left; }
.MuiTableContainer-root.simple-table.mtt-0 img.AVAILABILITY_CASH { float: none; max-width: 32px; margin-bottom: -3px; }

.MuiGrid-root.full-width.light-table .MuiTableContainer-root.simple-table { border-radius: 5px; overflow: hidden; box-shadow: 0px -3px 8px #d5d5d5; }
.MuiGrid-root.full-width.dark-table .MuiTableContainer-root.simple-table { border-radius: 5px; overflow: hidden; box-shadow: none; }
.MuiGrid-root.full-width.light-table .portan-title { background: #2B2B2B !important; }
.MuiGrid-root.full-width.light-table .portan-title th { text-align: center; color: #fff; ;}
.MuiGrid-root.full-width.light-table .MuiTableContainer-root.simple-table tr { border: none; background: #C4C5C5; }
.MuiGrid-root.full-width.light-table .MuiTableCell-head, .MuiGrid-root.full-width.light-table .MuiTableCell-root { text-align: center; padding: 13px !important; font-size: 15px; font-weight: 500; }
.MuiGrid-root.full-width.light-table .MuiTableContainer-root.simple-table tr:nth-child(2n) { background: #ECECEC; }
tr.MuiTableRow-root.portan-title.top-title.MuiTableRow-head { background: #3D3D3C !important; }
tr.MuiTableRow-root.portan-title.top-title.MuiTableRow-head th, tr.MuiTableRow-root.portan-title.top-title.MuiTableRow-head th:first-child { font-size: 20px; text-align: center !important; }
.mtt-0 { margin-top: 0px !important;}
.MuiGrid-root.full-width.dark-table .MuiTableContainer-root.simple-table.mb-5.mt-2 { border-radius: 5px; overflow: hidden; }
.MuiGrid-root.full-width.dark-table .portan-title { background: #3D3D3C !important; }
.MuiGrid-root.full-width.dark-table .MuiTableContainer-root.simple-table tr { border: none; }
.MuiGrid-root.full-width.dark-table .MuiTableCell-head, .MuiGrid-root.full-width.dark-table .MuiTableCell-root { padding: 13px !important; font-size: 15px; font-weight: 500; text-align: center; }
.MuiGrid-root.full-width.dark-table .MuiTableContainer-root.simple-table tr:nth-child(2n) { background: #191919; }
.MuiSvgIcon-root.eur-check { color: $success; }
.MuiSvgIcon-root.uncheck { color: $error; margin-top: 9px !important; }
img.atmimg { float: left; width: 100%; }
.MuiGrid-root.full-width.light-table.mtt-0.btm-table .MuiTableCell-root.MuiTableCell-head.text-center, .MuiGrid-root.full-width.dark-table.mtt-0.btm-table .MuiTableCell-root.MuiTableCell-head.text-center { text-align: center !important; }

.MuiGrid-root.full-width.btm-table tr.MuiTableRow-root.col td { padding: 7px 0; }
.MuiGrid-root.full-width.btm-table tr.MuiTableRow-root.col { width: calc(20% - 0px); float: left; padding: 8px 0;}
.MuiGrid-root.full-width.btm-table tr.MuiTableRow-root.col td:last-child { text-align: right; }
.MuiTypography-root.last { font-size: 16px; font-weight: 500; float: right; }
td.MuiTableCell-root.MuiTableCell-body.fullWidth { float: left; width: 100%; }

.MuiGrid-root.full-width.light-table .MuiTableContainer-root.simple-table tr td:first-child, .MuiGrid-root.full-width.light-table .portan-title th:first-child, .MuiGrid-root.full-width.dark-table .portan-title th:first-child { text-align: left !important;}
.MuiGrid-root.full-width.dark-table .MuiTableContainer-root.simple-table tr td:first-child, .MuiGrid-root.full-width.dark-table .portan-title th:first-child, .MuiGrid-root.full-width.dark-table .portan-title th:first-child { text-align: left !important;}
.MuiGrid-root.full-width.light-table .MuiTableContainer-root.simple-table tr td, .MuiGrid-root.full-width.dark-table .MuiTableContainer-root.simple-table tr td { padding-top: 5px !important; padding-bottom: 5px !important; }
/* Modal */
.MuiGrid-root.tabslikes button.MuiButtonBase-root.MuiButton-root.MuiButton-text { width: 50%; font-size: 16px; margin-top: 10px; border-bottom: 2px solid transparent !important; }
.MuiGrid-root.tabslikes button.MuiButtonBase-root.MuiButton-root.MuiButton-text.active { border-bottom: 2px solid $primary !important; border-radius: 0; color: $primary; }
.MuiGrid-root.tabslikes button.MuiButtonBase-root.MuiButton-root.MuiButton-text.active:hover, .MuiGrid-root.tabslikes button.MuiButtonBase-root.MuiButton-root.MuiButton-text:hover { background: transparent; color: $primary; border-bottom: 2px solid $primary !important; }
p.MuiTypography-root.dialog-title { font-size: 16px; font-weight: 600; margin-top: 16px; line-height: 21px; margin-bottom: 15px; }
p.MuiTypography-root.dialog-des { font-size: 15px; font-weight: 400; margin-bottom: 20px; }
.tabs-box .MuiDialogActions-root.btn-modal.MuiDialogActions-spacing .MuiButton-text { font-weight: 600; padding: 12px 10px; min-width: 190px; text-align: center; border: 2px solid $primary; }
.MuiDialogActions-root.btn-modal.MuiDialogActions-spacing .MuiButton-text.active, .MuiDialogActions-root.btn-modal.MuiDialogActions-spacing .MuiButton-text:hover { background: $primary; color: #fff; }
p.MuiTypography-root.dialog-des a { color: $primary; text-decoration: none; font-weight: 600; }
.tabs-box .MuiDialogActions-root.btn-modal.MuiDialogActions-spacing { margin: 15px 0; border-top: 2px solid #ededed; padding-top: 25px; }

.modal-custom.dark-modal-custom .tabs-box .MuiDialogActions-root.btn-modal.MuiDialogActions-spacing { border-color: #383838; }
.modal-custom.dark-modal-custom ul.main-modal li { border-color: #383838; }
.modal-custom.dark-modal-custom ul.main-modal li.react-tabs__tab--selected { border-color: $primary; }
.modal-custom .MuiAccordion-root:before { background: transparent; }
.MuiAccordionDetails-root .MuiTypography-root { font-size: 15px; font-weight: 500; padding-left: 10px; }
.MuiDialogActions-root.btn-modal.MuiDialogActions-spacing { margin: 15px 20px; }
p.MuiTypography-root.dialog-title-new { font-size: 16px; font-weight: 600; margin-top: 16px; line-height: 21px; margin-bottom: 15px; }
.MuiGrid-root.right-switch { float: right; position: absolute; z-index: 1; right: 0; top: 0px; }
.MuiGrid-root.tabslikes { border-bottom: 1px solid #dbdbdb; }
.MuiDialogContent-root.tb-lines { border-bottom: 1px solid #dbdbdb; }
.MuiAccordion-root.cstm-accordion:last-child { margin-bottom: 20px; margin-top: 10px; border-bottom: 1px solid #DCDCDC; border-radius: 0;}
.MuiGrid-root.inner-content { float: left; width: 100%; }
.MuiGrid-root.Cookiebot-content p.MuiTypography-root.inner-title { padding-left: 0; font-weight: 600; }
.MuiGrid-root.Cookiebot-content p.MuiTypography-root.inner-title span { background: #DCDCDC; padding: 2px 6px; border-radius: 5px; margin-left: 4px; font-size: 13px; font-weight: 600; }
.MuiGrid-root.loop-accordion { position: relative; }
.MuiGrid-root.Cookiebot-content a { float: left; font-size: 15px; font-weight: 600; margin-top: 3px; margin-bottom: 10px; }
.MuiGrid-root.Cookiebot-content a svg { margin-top: 5px; width: 15px; }
.MuiGrid-root.Cookiebot-content a svg { margin-top: 3px; width: 15px; float: right; margin-left: 8px; }
.MuiGrid-root.Cookiebot-content { padding: 15px 20px; border: 1px solid #cbcbcb; border-radius: 5px; float: left; width: calc(100% - 12px); margin-top: 15px; margin-left: 12px; }
span.bold { font-weight: 600; }
.MuiGrid-root.inner-sub-content { float: left; width: 100%; padding: 15px; background: #F2F2F2; border-radius: 10px; margin-top: 10px; }
p.MuiTypography-root.innter-title-main { font-weight: 600; padding-left: 0;  }
p.MuiTypography-root.innter-title-des { font-weight: 400; border-bottom: 1px solid #c5c4c4; padding-left: 0; padding-bottom: 10px; margin-bottom: 15px; }
.MuiGrid-root.Cookiebot-content p.MuiTypography-root { padding-left: 0; }
.MuiGrid-root.scroll-content { max-height: calc(100vh - 250px); overflow: auto; }
span.MuiSwitch-root.switch-size.green .Mui-checked + .MuiSwitch-track { background: #7BAE3E !important;}
span.MuiSwitch-root.switch-size.orange .Mui-checked + .MuiSwitch-track { background: #f74d01 !important;}
label.MuiFormControlLabel-root.switch-size.green .Mui-checked + .MuiSwitch-track { background: #7BAE3E !important;}
label.MuiFormControlLabel-root.switch-size.orange .Mui-checked + .MuiSwitch-track { background: #f74d01 !important;}
label.MuiFormControlLabel-root.switch-size .MuiSwitch-track { background: red !important; opacity: 1;}
label.MuiFormControlLabel-root.switch-size { margin-left: 10px; margin-right: 5px; margin-top: -2px; }
label.MuiFormControlLabel-root.switch-size .MuiSwitch-root { width: 32px; height: 16px; }
label.MuiFormControlLabel-root.switch-size .MuiSwitch-thumb { height: 14px; width: 14px; }
label.MuiFormControlLabel-root.switch-size .MuiSwitch-thumb { transform: translateX(-1px); }
.MuiContainer-root.dark-tablecontent .MuiTypography-root.info1-list { color: #C4C5C5;}
.MuiContainer-root.dark-tablecontent span.MuiTypography-root.day-list { color: #C4C5C5; }
.MuiContainer-root.dark-tablecontent span.MuiTypography-root.day-time { color: #C4C5C5; }
.MuiContainer-root.dark-tablecontent tr.MuiTableRow-root.portan-title.top-title.MuiTableRow-head { background: #2B2B2B !important;}
.MuiGrid-root.full-width.light-table.mtt-0.btm-table tr.MuiTableRow-root.portan-title.MuiTableRow-head { background: #3D3D3C !important; }
.MuiGrid-root.full-width.dark-table.mtt-0.btm-table tr.MuiTableRow-root.portan-title.MuiTableRow-head { background: #2B2B2B !important; }
.MuiGrid-root.full-width.light-table.mtt-0.btm-table tr.MuiTableRow-root.portan-title.MuiTableRow-head th { font-size: 20px; }
.MuiGrid-root.full-width.dark-table.mtt-0.btm-table tr.MuiTableRow-root.portan-title.MuiTableRow-head th { font-size: 20px; }
.MuiGrid-root.full-width.dark-table .MuiTableContainer-root.simple-table tr { background: #191919; }
.MuiGrid-root.full-width.dark-table .MuiTableContainer-root.simple-table tr:nth-child(2n) { background: #3D3D3C; }
img.atm-status { max-width: 15px; position: absolute; left: -2px; top: 9px; }
.MuiDialog-root.modal-custom .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded { max-width: 860px !important; width: 100%; }
ul.main-modal { margin: 0; padding: 0; }
ul.main-modal li { width: 33%; float: left; list-style: none; text-align: center; padding: 25px 0 15px 0; font-size: 16px; font-weight: 700; border-bottom: 3px solid #DCDCDC; cursor: pointer; }
ul.main-modal li.react-tabs__tab--selected { border-color: $primary; }
.tabs-box { float: left; width: 100%; padding: 0; }
.tabs-content { padding: 15px 25px; float: left; width: 100%; max-height: calc(100vh - 240px); height: 100%; overflow: auto; }
.MuiAccordion-root.cstm-accordion .MuiAccordionSummary-root { padding: 0; padding-left: 25px; }
.MuiAccordion-root.cstm-accordion .MuiIconButton-root { position: absolute; left: -30px; }
.MuiAccordion-root.cstm-accordion .MuiAccordionSummary-content p { font-size: 15px; font-weight: 600; }
.cstm-accordion.Mui-expanded .MuiSvgIcon-root { -ms-transform: rotate(180deg); transform: rotate(180deg);}
.cstm-accordion.Mui-expanded .MuiAccordionSummary-root.Mui-expanded { min-height: 29px; }
.MuiAccordion-root.cstm-accordion .MuiAccordionSummary-content.Mui-expanded, .MuiAccordion-root.cstm-accordion { margin: 0px ;}
.MuiGrid-root.makeStyles-bgWhite-3.mobile-inner-pad { padding: 50px 40px !important; }
.tr-tabs ul { margin: 0; padding: 0; margin-bottom: 25px; width: 100%; float: left; }
.tr-tabs ul li { cursor: pointer; list-style-type: none; font-size: 14px; font-weight: 600; text-transform: uppercase; float: left; padding: 5px 20px; border-radius: 5px; background: #3C1360; color: #fff; margin-right: 6px; }
.tr-tabs-details { float: left; width: 100%; }
.tr-tabs ul li.react-tabs__tab.react-tabs__tab--selected { background: $primary; color: #fff; }
.MuiGrid-root.cstm-crypto-table { margin-top: 25px; }
.MuiGrid-root.cstm-crypto-table { border-radius: 10px; overflow: hidden; box-shadow: 0 4px 6px -2px rgb(0 0 0 / 12%), 0 2px 2px -1px rgb(0 0 0 / 5%); }
.MuiGrid-root.cstm-crypto-table h6 { font-size: 20px; }
.MuiGrid-root.cstm-crypto-table .MuiToolbar-root.MuiToolbar-regular { padding-top: 15px; }
.MuiGrid-root.cstm-crypto-table .MuiTableCell-root { font-size: 14px !important; font-weight: 500 !important; }
.MuiGrid-root.cstm-crypto-table th.MuiTableCell-root { font-weight: 700 !important; font-size: 15px !important;}
.MuiGrid-root.cstm-crypto-table.dark-table .MuiSelect-icon { color: #fff !important; }
.MuiGrid-root.cstm-crypto-table.dark-table .MuiIconButton-root.Mui-disabled { color: rgba(255, 255, 255, 0.3); }
.MuiGrid-root.cstm-crypto-table.light-table tr th:first-child { width: 25px !important; }
label.MuiFormControlLabel-root.switch-size span.MuiIconButton-label span { background: #fffffd !important; }
.cardinfonew .cardbalance.credit { font-size: 15px; margin-top: 10px; color: #666666; }
.cardinfonew .cardbalance.credit + .MuiTypography-root.cardnumber { font-size: 29px; font-weight: 600; border-bottom: 1px solid #898989; padding-bottom: 2px; margin-bottom: 0px; }
.cardinfonew.dark .cardbalance.credit, .cardinfonew.dark .cardbalance.available { color: #ccc; }
.cardinfonew.dark .cardbalance.credit + .MuiTypography-root.cardnumber  { border-color: #383838;  }
.cardinfonew .cardbalance.available { font-size: 15px; color: #666666; }
.cardinfonew .cardbalance.available + .MuiTypography-root.cardnumber { font-size: 27px; font-weight: 300 !important; margin-bottom: 5px; }
.MuiGrid-root.Accountbox { padding: 6px 0; background: #CCCCCC; position: relative;}
.cardinfonew.dark .MuiGrid-root.Accountbox { background: #1C1C1C; }
.right-border { border-right: 1px solid #7F7F7F ;}
.cardinfonew.dark .right-border { border-color: #383838; }
.MuiGrid-root.crypto-table tr.MuiTableRow-root.MuiTableRow-head, .MuiGrid-root.crypto-table .MuiTableRow-root:nth-of-type(odd) { background: transparent !important; }
.MuiGrid-root.crypto-table .MuiTableCell-body, .MuiGrid-root.crypto-table .MuiTableCell-head { padding-top: 3px !important; padding-bottom: 3px !important; font-weight: 600; }
.MuiGrid-root.crypto-table { padding: 8px 0px 8px 0px; overflow: auto; min-height: 225px; }
.MuiGrid-root.crypto-table-mod { min-height: 200px !important; }
// #chart > .chart-container svg { min-height: 300px !important; margin-bottom: 5px; }
.graphs.light .MuiGrid-root.graphs-space.chart-new .chart-container svg { height: 370px !important; }
.MuiGrid-root.crypto-table-mod svg.frappe-chart.chart { min-height: 260px; }
.MuiGrid-root.crypto-table-mod-2 { padding: 33px 0px !important; }
.MuiGrid-root.order-3-new { order: 3; }
.MuiGrid-root.order-2-new button { margin: 19px auto 0; display: table; float: none; width: auto; min-width: 120px; }
div.credit-tabs-main { margin: 0; padding: 0; margin-bottom: 15px; float: left; width: 100%; }
.crypto-table.new-box-dash, .Accountbox.new-box-dash-head { padding-left: 25px; }
.MuiGrid-root.bdr-table { height: 195px; background: #E3E4E1; width: 1px; position: absolute; left: 14px; top: 20px; }
div.credit-tabs-main a.react-tabs__tab--selected { background: #723789; color: #fff; }
div.credit-tabs-main a { float: left; list-style: none; background: #E4E4E4; color: #000; padding: 11px 25px; font-size: 17px; border-radius: 5px; margin-right: 15px; cursor: pointer; }
.MuiGrid-root.bdr-table-head { background: #E2E1E3; width: 1px; position: absolute; left: 14px; top: 16px; height: 55px; }
.hidden-overflow .MuiPaper-rounded { overflow: hidden; }
.dark .MuiGrid-root.bdr-table-head, .dark .MuiGrid-root.bdr-table { background: #545454; }
.hidden-overflow .MuiPaper-rounded .MuiTableCell-root { padding-top: 6px !important; padding-bottom: 6px !important;}
.MuiGrid-root.hidden-overflow button.MuiButtonBase-root.MuiIconButton-root { padding-top: 5px !important; padding-bottom: 5px !important; }
.MuiGrid-root.hidden-overflow.dark .MuiIconButton-root.Mui-disabled { opacity: 0.3;}
.MuiGrid-root.hidden-overflow.dark .MuiIconButton-root { color: #767676; }
.MuiGrid-root.hidden-overflow.dark .MuiInputBase-root .MuiSvgIcon-root { color: #767676; }
.MuiGrid-root.hidden-overflow .MuiTypography-h6 { font-size: 25px; }
p.MuiTypography-root.order-title.Withdrawal-lable { margin-top: 22px; }
// .MuiGrid-root.hidden-overflow td.MuiTableCell-root.MuiTableCell-footer.MuiTablePagination-root { display: none; }
.MuiGrid-root.news-new a { text-decoration: none; }
.MuiGrid-root.news-new { float: left; width: 100%; margin-top: 15px; text-align: center; }
.MuiGrid-root.hidden-overflow .MuiTableRow-root { height: auto !important; }
.MuiGrid-root.hidden-overflow .MuiTableRow-root { height: auto !important; }
.external-iframe { position: fixed; border: none;  z-index: 9999; transition: left 1s ease 0s, bottom 1s ease 0s, right 1s ease 0s; height: 115px; width: 250px; left: auto; right: 25px; bottom: 25px; }
.none-hidden-overflow .MuiPaper-rounded { overflow: hidden; }
.none-hidden-overflow .MuiPaper-rounded .MuiTableCell-root { padding-top: 6px !important; padding-bottom: 6px !important;}
.none-hidden-overflow-2 .MuiPaper-rounded .MuiTableCell-root { padding-top: 6.6px !important; padding-bottom: 6.6px !important;}
.MuiGrid-root.none-hidden-overflow button.MuiButtonBase-root.MuiIconButton-root { padding-top: 5px !important; padding-bottom: 5px !important; }
.MuiGrid-root.none-hidden-overflow.dark .MuiIconButton-root.Mui-disabled { opacity: 0.3;}
.MuiGrid-root.none-hidden-overflow.dark .MuiIconButton-root { color: #767676; }
.MuiGrid-root.none-hidden-overflow.dark .MuiInputBase-root .MuiSvgIcon-root { color: #767676; }
.MuiGrid-root.none-hidden-overflow .MuiTypography-h6 { font-size: 25px; }

// .MuiGrid-root.none-hidden-overflow td.MuiTableCell-root.MuiTableCell-footer.MuiTablePagination-root { display: none; }

.MuiGrid-root.Accountbox.MuiGrid-item .MuiTypography-root.cardnumber.font-600 { font-weight: 600 !important;}
img.crypto { max-width: 19px; float: left; margin-right: 6px; margin-top: 2px; }
.MuiGrid-root.cardinfo.Accountbox.cstm-outgoinpad-new { padding: 14px 0; }
.MuiGrid-root.crypto-table .MuiTableCell-body, .MuiGrid-root.crypto-table tbody tr.MuiTableRow-root { border-bottom: 1px solid #E3E3E3; }
.MuiGrid-root.crypto-table .MuiTableHead-root { background: transparent !important;}
.cardinfonew.dark .MuiGrid-root.crypto-table .MuiTableCell-body, .cardinfonew.dark .MuiGrid-root.crypto-table tbody tr.MuiTableRow-root { border-color: #545454 !important;}
.MuiGrid-root.crypto-table tr.MuiTableCell-body:last-child, .MuiGrid-root.crypto-table tbody tr.MuiTableRow-root:last-child { border: none !important;}
svg.setting-icon { position: absolute; right: 5px; top: 4px; }
.MuiGrid-root.crypto-table td.MuiTableCell-root.MuiTableCell-body.minus-value.plus-value { color: #1c8f3e;}
.MuiGrid-root.crypto-table td.MuiTableCell-root.MuiTableCell-body.minus-value { color: #F93452;}
.MuiTypography-root.crypto-title { font-size: 25px; font-weight: 600; line-height: 1.6; padding: 16px; }
img.graphs { max-width: 305px; margin: 16px auto; display: table; width: 100%; padding: 10px; }
img.graphs1 { max-width: 416px; margin: 16px auto; display: table; width: 100%; padding: 10px; }
p.MuiTypography-root.cardnumber.border_none { border: none !important; }
p.MuiTypography-root.comming-soon { font-size: 9px; font-weight: 500; text-align: center; }
img.big-coin { max-width: 100px; margin: 20px auto; }
.MuiGrid-root.center-box { position: absolute; top: 50%; transform: translateY(-50%); left: 0; right: 0; }
.MuiGrid-root.center-box .plus-value { font-size: 35px; }
.MuiGrid-root.center-box .MuiTypography-root.total-btc { font-size: 15px; font-weight: 600; }
button.Buybtn { color: #fff; float: left; padding: 10px 20px; border-radius: 2px; text-decoration: none; background-color: #69A840; float: left; margin-right: 10px;}
button.Buybtn:hover { background: #69A840; color: #fff; }
button.Sellbtn { color: #fff; float: left; padding: 10px 20px; border-radius: 2px; text-decoration: none; background-color: #C20116; float: left; margin-right:0px;}
button.Sellbtn:hover { background: #69A840; color: #fff; }
.MuiGrid-root.btnexample { float: left; width: 100%; }
p.MuiTypography-root.order-title { float: left; width: 100%; -size: 16px; margin-bottom: 5px; font-weight: 500; }
.MuiFormControl-root.cstm-selectbox .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input { width: 40px; margin-left: auto; }
.MuiGrid-root.overlap-box .textbox-overlap { position: absolute; border: none; z-index: 111; width: calc(100% - 112px); right: 0; top: 36px; left: 9px; }
.MuiGrid-root.overlap-box .textbox-overlap .MuiInput-underline:before{ border: none;  }
.MuiGrid-root.overlap-box .textbox-overlap .MuiInputBase-input { font-size: 0.875rem; font-weight: 400; padding-left: 15px;  min-height: 36px; margin-top: 3px; min-width: 130px !important; }
.MuiGrid-root.overlap-box .textbox-overlap .MuiInput-underline:after { border: none !important	;}
p.MuiTypography-root.Estimate-time.MuiTypography-body1 { opacity: 0.5; margin-top: 3px; font-weight: 500; }
.MuiButton-root.buyltc { margin: 15px auto 5px; display: table; font-size: 25px; background: #723789; padding: 6px 38px; color: #fff; max-width: 365px; width: 100%;}
.space--remove .MuiBox-root { padding: 0; margin-top: 15px; }
header.MuiPaper-root.default-tabs { background: transparent; box-shadow: none; }
header.MuiPaper-root.default-tabs a.MuiButtonBase-root { text-transform: capitalize; padding: 0; padding-left: 0; min-width: 90px; margin-right: 25px; font-size: 18px;}
header.MuiPaper-root.default-tabs.dark a.MuiButtonBase-root { color: #fff; }
header.MuiPaper-root.default-tabs .MuiTabs-indicator { background-color: $primary; }
.table-rounded .MuiPaper-rounded { overflow: hidden; }
.MuiGrid-root.highlight-box .MuiTypography-root.Staking { background: $primary; width: 100%; margin: 0 auto; display: table; max-width: 100%; text-align: center; font-size: 23px; padding: 16px 0; border-radius: 5px; font-weight: 500; color: #fff; }
.MuiGrid-root.highlight-box .MuiTypography-root.Staking span { font-weight: 600; }
.MuiGrid-root.highlight-box.color-blue .MuiTypography-root.Staking { background: #723789; }
.MuiGrid-root.highlight-box { margin: 10px 0; }
.for-bank.Withdrawal-address.viewable-text.dark .tooltip-box.tootltip-copys button { color: #fff; }
p.MuiTypography-root.deposits-title { font-size: 25px; text-align: center; width: 100%; font-weight: 500; }
.img-select { float: left; width: 60px; }
.img-select img { width: 45px; float: left; box-shadow: none; height: auto; margin-top: 0px; border: none; padding-bottom: 0 !important; padding-right: 0px; border-radius: 0px; }
.img-data-select { float: left; width: calc(100% - 60px); }
.img-data-select p.acount-number { margin-top: 0px; margin-bottom: 0; text-transform: uppercase; }
.MuiFormControl-root.cstm-select.selct-height .MuiSelect-outlined.MuiSelect-outlined { min-height: 43px; }
.MuiFormControl-root.cstm-select.selct-height.eur-select .MuiSelect-outlined.MuiSelect-outlined { min-height: 31px; }
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl .img-data-select p.acount-number { margin-top: 5px; }
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl .img-select.oneline-select + .img-data-select p.acount-number { margin-top: 7px !important; }
.MuiGrid-root.deposit-btn-qr { float: left; width: 100%; }
.MuiGrid-root.deposit-btn-qr Button { margin: 18px auto 5px; display: table; float: none; }
.MuiGrid-root.qr-img { margin: 10px auto; display: table; }
.MuiGrid-root.wallet-address .MuiTypography-root.wallet-address-text { float: left; width: calc(100% - 35px); font-size: 15px; margin-top: 2px; overflow: hidden; }
.tooltip-box { width: 35px; float: left;}
.MuiGrid-root.wallet-address svg.MuiSvgIcon-root { width: 22px; margin-bottom: -5px; height: auto; margin-top: 1px; }
.tooltip-box button { margin: 0; padding: 0; margin-left: 12px; margin-top: 1px; }
.MuiGrid-root.wallet-address { float: left; width: 100%; background: rgba(0, 0, 0, 0.1); padding: 5px 2px 5px 25px; border-radius: 5px;  }
.tooltip-box button:hover { background: transparent; color: $primary; }
.MuiGrid-root.confirmations-text { float: left; width: 100%; margin-top: 15px; border: 1px solid #8E8E8E; padding: 14px; border-radius: 5px; }
.MuiGrid-root.confirmations-text p { font-size: 15px; }
.MuiGrid-root.address-status { float: left; width: 100%; margin-top: 15px; }
.MuiGrid-root.address-line { float: left; width: 100%; border-bottom: 1px solid #C5C5C5; }
p.MuiTypography-root.status-address { float: left; margin-right: 10px; font-size: 15px; font-weight: 500; padding: 2px 0; }
p.MuiTypography-root.Used-address { float: right; font-size: 15px; font-weight: 500; }
.MuiGrid-root.wallet-address.dark { background: #1c1c1c; }
.MuiGrid-root.confirmations-text + .MuiGrid-root.address-status .MuiGrid-root.address-line { border-color: #8F8F8F; }
.MuiGrid-root.wallet-address.dark svg.MuiSvgIcon-root { color: #fff; }
.MuiGrid-root.deposit-table { float: left; width: 100%; margin-top: 35px; }
.MuiGrid-root.deposit-table h6 { font-weight: 500; font-size: 19px; }
p.MuiTypography-root.deposits-title-inner img { float: left; width: 26px; margin-right: 5px; }
p.MuiTypography-root.deposits-title-inner span { font-weight: 600; font-size: 20px; float: left; margin-top: 2px; }
.MuiGrid-root.progressbar { float: left; background: #B0B1B1; height: 9px; width: 150px; overflow: hidden; border-radius: 5px; margin-top: 8px; margin-left: 15px; position: relative; }
p.MuiTypography-root.status-address span { float: left; }
.MuiGrid-root.activeprogress { position: absolute; background: #272727; height: 10px; }
.MuiGrid-root.button-group { float: left; width: 100%; margin-top: 22px; }
.MuiGrid-root.button-group p.MuiTypography-root.Funding-limit { float: left; font-weight: 600; font-size: 20px; margin-right: 20px; margin-top: 5px; }
.MuiGrid-root.copybutton { margin-bottom: 40px; }
.MuiGrid-root.copybutton > button.MuiButtonBase-root.MuiIconButton-root { padding: 10px 20px; float: none; margin: 0 auto; display: table; min-width: auto; width: auto; border-radius: 8px; }
.MuiGrid-root.copybutton > button.MuiButtonBase-root.MuiIconButton-root span { margin: 0px; }
.MuiGrid-root.copybutton > button.MuiButtonBase-root.MuiIconButton-root span button { margin: 0; padding: 0; }
.MuiGrid-root.button-group .MuiButtonGroup-root { float: left; }
.MuiGrid-root.button-group .MuiButtonGroup-root button.active, .MuiGrid-root.button-group .MuiButtonGroup-root button:hover { background: #272727 !important; color: #fff; }
.MuiGrid-root.progressbar.dark { background: #575757; }
.MuiGrid-root.progressbar.dark .MuiGrid-root.activeprogress { background: #FFFFFF;}
.MuiGrid-root.button-group.dark .MuiButton-outlined { border-color: #fff !important;}
.MuiGrid-root.button-group.dark .MuiButtonGroup-root button.active, .MuiGrid-root.button-group.dark .MuiButtonGroup-root button:hover { background: #fff !important; color: #000; }
.MuiGrid-root.deposit-btn-qr.withbtn button { background: #272727; margin-bottom: 25px; }
.MuiGrid-root.add-new-address { float: left; width: 100%; margin-top: 25px; margin-bottom: 20px; }
.MuiGrid-root.add-new-address button { background: #D9D9D9; border: 1px solid #8C8C8C; color: #000; margin-right: 10px; padding: 10px 14px; margin-bottom: 5px; }
.p-relative { position: relative !important; }
.MuiGrid-root.withdrawal-amount-f { float: left; width: 100%; margin-top: 25px; position: relative; }
.MuiGrid-root.withdrawal-amount-f .MuiOutlinedInput-root input { font-size: 25px; text-align: right; font-weight: 600; padding-right: 80px; } 
.MuiGrid-root.withdrawal-amount-f img.profile-icon { position: absolute; max-width: 65px; right: 0; border: none; top: 14px; border-left: 1px solid #D9D9D9; padding-bottom: 0; padding-left: 10px; }
.MuiGrid-root.hrline { height: 218px; background: #DDDDDD; width: 1px; position: absolute; left: 0; right: 0; margin: auto; pointer-events: none; top: 5px; }
.MuiGrid-root.deposit-btn-qr.withbtn.dark button { background: #FFFFFF; color: #000; }
.img-select.oneline-select { width: 40px; }
.img-select.oneline-select img { max-width: 30px; }
.img-select.oneline-select + .img-data-select { width: calc(100% - 40px);}
.img-select.oneline-select + .img-data-select p.acount-number { font-size: 21px; font-weight: 500; margin-top: 3px; }
.img-select.oneline-select + .img-data-select p.acount-number span { opacity: 0.4; font-weight: 400; }
.MuiGrid-root.eur-balance { float: left; width: 100%; border: 1px solid #C4C4C4; border-radius: 5px; margin-top: 0px; }
p.MuiTypography-root.coin-text { font-size: 20px; padding: 10px 15px; }
p.MuiTypography-root.coin-price {  background: #ECECEC; font-size: 20px; padding: 10px 15px; text-align: right; border-left: 1px solid #C4C4C4; }
.MuiGrid-root.withdrawal-amount-f .MuiGrid-root.Withdrawal-address.viewable-text img.profile-icon { left: 0; right: auto; border-right: 1px solid #D9D9D9; border-left: none; max-width: 46px; padding-right: 10px; margin-top: -2px; }
.MuiGrid-root.withdrawal-amount-f .MuiGrid-root.Withdrawal-address.viewable-text .MuiOutlinedInput-root input { text-align: left; font-size: 17px; font-weight: 400; padding: 15px 10px 15px 60px;}
.MuiGrid-root.tooltip-box.tootltip-copys { position: absolute; right: 4px; top: 10px; }
.MuiGrid-root.deposit-box { float: left; width: 100%; margin-top: 0px; margin-bottom: 10px; }
.mt-0 { margin-top: 0px !important; }
.MuiGrid-root.transactions-tabs a { background: #D9D9D9; color: #000; border: 2px solid #000; border-radius: 5px; }
.MuiGrid-root.transactions-tabs a.Mui-selected, .MuiGrid-root.transactions-tabs.dark a.Mui-selected { background: #000; color: #fff;}
.MuiGrid-root.transactions-tabs header.MuiPaper-root.default-tabs .MuiTabs-indicator { background: transparent;}
.MuiGrid-root.transactions-tabs.dark header.MuiPaper-root.default-tabs .MuiTabs-indicator { background: transparent;}
.MuiGrid-root.transactions-tabs.dark a { background: #fff; opacity: 1; }
.MuiGrid-root.withdrawal-amount-f.dark .MuiGrid-root.tooltip-box.tootltip-copys button { color: #fff; }
.MuiGrid-root.withdrawal-amount-f .MuiGrid-root.tooltip-box.tootltip-copys button:hover { color: $primary !important;}
.MuiGrid-root.eur-balance.dark p.MuiTypography-root.coin-price { background: #B0B2B1; color: #000; }
.MuiGrid-root.for-bank .MuiSelect-root.MuiSelect-select { padding: 9.5px; }
.MuiGrid-root.for-bank .img-select.oneline-select img { max-width: 25px; border-right: 1px solid #D9D9D9; padding-right: 10px; }
.MuiGrid-root.for-bank .img-select.oneline-select + .img-data-select p { font-size: 15px !important; font-size: 17px !important; font-weight: 400 !important; text-align: left; padding: 0 0px 0px 11px; margin-top: 0 !important; }
.MuiGrid-root.for-bank .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl .img-select.oneline-select + .img-data-select p.acount-number { margin-top: 7px !important;}
p.MuiTypography-root.fio-bank { font-size: 16px; font-weight: 500; margin-bottom: 2px; }
.MuiGrid-root.for-bank .MuiGrid-root.tooltip-box.tootltip-copys { top: 42px; right: 25px; }
.MuiGrid-root.for-bank  .img-select.oneline-select { margin-top: 2px; }
.cardinfonew.reverce-color .MuiGrid-root.Accountbox { background: #fff; }
.cardinfonew.reverce-color .MuiGrid-root.crypto-table { background: #CCCCCC; }
.cardinfonew.reverce-color.dark .MuiGrid-root.Accountbox { background: #272727; }
.cardinfonew.reverce-color.dark .MuiGrid-root.crypto-table { background: #1C1C1C; }
.MuiTableCell-sizeSmall { padding: 5px !important; }
.MuiGrid-root.portfolio-grid-space { float: left; width: 100%; padding: 0 20px; margin-bottom: 20px; }
p.MuiTypography-root.portfolio-title { font-weight: 400; margin-bottom: 5px; float: left; width: 100%;  }
.MuiGrid-root.portfolio-line { float: left; width: 100%; position: relative; background: #DFDFDF; height: 6px; border-radius: 5px; overflow: hidden; }
.MuiGrid-root.portfolio-active-line.credit-balance { background: #259447; float: left; position: absolute; height: 6px; }
p.MuiTypography-root.percentage { float: right; }
.MuiGrid-root.portfolio-active-line.crypto-balance { background: #DC9018; float: left; position: absolute; height: 6px; }
.MuiGrid-root.portfolio-active-line.profit-earning { background: #59277F; float: left; position: absolute; height: 6px; }
.MuiGrid-root.portfolio-active-line.ATM-credit { background: #4370B3; float: left; position: absolute; height: 6px; }
.MuiGrid-root.portfolio-active-line.voucher-balance { background: #A21B25; float: left; position: absolute; height: 6px; }
.MuiGrid-root.News-box { padding: 0 18px; float: left; width: 100%; margin-bottom: 0; margin: 0px; }
p.MuiTypography-root.date-title { font-size: 17px; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; }
.MuiGrid-root.border-btm-new { border-bottom: 1px solid #C2C2C2; padding: 6px 5px 6px 0px !important; }
.none-border { border: none !important; }
.MuiPaper-root.progressbar.dark .MuiGrid-root.portfolio-line { background: #575755; }
.MuiCard-root.p-0.mb-3.mt-1.light.news-min-height { min-height: 412px; }
.dark .MuiGrid-root.border-btm-new { border-bottom-color:#787878 ;}
.MuiGrid-root.Status { border: 2px solid #6F6F6F; border-radius: 25px; padding: 1px 4px; text-align: center; float: left; min-width: 107px; }
.MuiGrid-root.circle { float: left; width: 19px; height: 19px; border: 2px solid #6F6F6F; border-radius: 50%; margin-top: 2px; margin-right: 6px; margin-bottom: 2px; }
.MuiTypography-root.status-text { float: left; font-size: 13px; padding-right: 6px; min-width: 60px; }
.MuiGrid-root.Status.canceled .MuiGrid-root.circle { background: #C20116; border-color: #C20116; }
.MuiGrid-root.Status.done .MuiGrid-root.circle { background: #69A840; border-color: #69A840; }
.MuiGrid-root.stake-button button { background: $primary; color: #fff; min-width: 110px; text-align: center; margin: 0 10px; }
.MuiGrid-root.unstake-button button { background: #272727; color: #fff; min-width: 110px; text-align: center; margin: 0 10px; }
.MuiGrid-root.unstake-button button:hover { background: #272727; }
button.unstake-button { background: #272727 !important; color: #fff; min-width: 110px; text-align: center; margin: 0 10px; }
button.unstake-button:hover { background: #272727; }
.MuiGrid-root.unstake-button.dark button { background: #fff; color: #000; }
.MuiGrid-root.success-button button { background: transparent; border: 1px solid $primary; border-radius: 35px; padding: 4px 18px; color: $primary; box-shadow: none; }
.MuiGrid-root.success-button.failed button { border-color: red; color: red; }
button.MuiButton-root.submit-btn { margin-bottom: 10px !important; background: #DC9018; color: #fff; min-width: 110px; text-align: center; margin: 0 10px; }
button.MuiButton-root.cancel-btn { margin-bottom: 10px !important; background: #FFFFFF; color: #000; min-width: 110px; text-align: center; margin: 0 10px; }
p.MuiTypography-root.or { margin: -13px auto 0; display: table; background: #fff; padding: 0 15px; font-weight: 500; }
.MuiGrid-root.media-login { margin: 25px auto; display: table; }
.MuiGrid-root.media-login img { max-width: 28px; width: 100%; margin: 0 7px; }
.MuiGrid-root.media-login a { text-decoration: none; float: left; }
.MuiGrid-root.media-login div { float: left; }
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded .MuiMenu-list.MuiList-padding { padding: 8px 0; padding-right: 0 !important; }
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded .MuiMenu-list.MuiList-padding li.MuiButtonBase-root { margin: 0; }

.MuiGrid-root.agreeCheckbox { float: left; width: 100%; text-align: center; margin-bottom: 10px; margin-top: -5px; }
.MuiGrid-root.agreeCheckbox label { margin-right: 0px; margin-top: -2px; }
.MuiGrid-root.agreeCheckbox a { color: #DC9018; }



/** Lang CSS Start */

.sp .sp-show, .rus .sp-show, .hg .sp-show { display: none; } 
.sl .MuiGrid-root.menu-space.MuiGrid-item { width: calc(100% - 530px); }
.cz .MuiGrid-root.menu-space.MuiGrid-item { width: calc(100% - 540px); }
.gr .MuiGrid-root.menu-space.MuiGrid-item { width: calc(100% - 461px); }
.gr .MuiGrid-root.left-menu.MuiGrid-item { width: 430px; }
.gr .MuiGrid-root.right-menu.MuiGrid-item { width: calc(100% - 450px);}
.sp .MuiGrid-root.menu-space.MuiGrid-item { width: calc(100% - 516px); }
.sp .MuiGrid-root.left-menu.MuiGrid-item { width: 290px; }
.sp .MuiGrid-root.right-menu.MuiGrid-item { width: calc(100% - 300px);}
.rus .MuiGrid-root.menu-space.MuiGrid-item { width: calc(100% - 530px); }
.rus .MuiGrid-root.left-menu.MuiGrid-item { width: 230px; }
.rus .MuiGrid-root.right-menu.MuiGrid-item { width: calc(100% - 240px);}
.grm .MuiGrid-root.menu-space.MuiGrid-item { width: calc(100% - 505px); }
.grm .MuiGrid-root.left-menu.MuiGrid-item { width: 285px; }
.grm .MuiGrid-root.right-menu.MuiGrid-item { width: calc(100% - 312px);}
.hg .MuiGrid-root.menu-space.MuiGrid-item { width: calc(100% - 528px); }
.hg .MuiGrid-root.left-menu.MuiGrid-item { width: 220px; }
.hg .MuiGrid-root.right-menu.MuiGrid-item { width: calc(100% - 220px);}

@media (min-width: 320px) and (max-width: 1222px) {
	.sl .MuiGrid-root.menu-space.MuiGrid-item { width: 100%; padding-left: 0px; padding-right: 0px; }
	.MuiGrid-root.right-menu.MuiGrid-item link.MuiButtonBase-root.MuiButton-root.MuiButton-text { margin-left: 0;  }
	.cz .MuiGrid-root.menu-space.MuiGrid-item { width: 100%; padding-left: 0px; padding-right: 0px; }
	.gr .MuiGrid-root.menu-space.MuiGrid-item { width: 100%; padding-left: 0px; padding-right: 0px; }
	.gr .MuiGrid-root.right-menu.MuiGrid-item link.MuiButtonBase-root.MuiButton-root.MuiButton-text { margin-left: 5px;}
	.sp .MuiGrid-root.menu-space.MuiGrid-item { width: 100%; padding-left: 0px; padding-right: 0px; }
	.rus .MuiGrid-root.menu-space.MuiGrid-item { width: 100%; padding-left: 0px; padding-right: 0px; }
	.grm .MuiGrid-root.menu-space.MuiGrid-item { width: 100%; padding-left: 0px; padding-right: 0px; }
	.hg .MuiGrid-root.menu-space.MuiGrid-item { width: 100%; padding-left: 0px; padding-right: 0px; }
	
	.menu-link a.MuiButtonBase-root.MuiButton-text { margin-left: 3px; }
}

@media (min-width: 1223px) and (max-width: 1245px) { 
	.gr .MuiGrid-root.left-menu.MuiGrid-item { width: 412px;}
	.gr .MuiGrid-root.right-menu.MuiGrid-item { width: calc(100% - 420px); }
	.sp .MuiGrid-root.left-menu.MuiGrid-item { width: 270px; }
	.sp .MuiGrid-root.right-menu.MuiGrid-item { width: calc(100% - 275px); }
	.rus .MuiGrid-root.left-menu.MuiGrid-item { width: 215px; }
	.rus .MuiGrid-root.right-menu.MuiGrid-item { width: calc(100% - 220px);}
	.grm .MuiGrid-root.right-menu.MuiGrid-item { width: calc(100% - 300px);}
}

@media (min-width:768px) {
	.gr .MuiToolbar-gutters { padding-left: 0; padding-right: 0; }
	.sp .MuiToolbar-gutters { padding-left: 0; padding-right: 0; }	
}
@media (max-width:767px) { 
	.gr .MuiGrid-root.right-menu.MuiGrid-item { width: auto; }
	.sp .MuiGrid-root.right-menu.MuiGrid-item { width: auto; }
	.rus .MuiGrid-root.right-menu.MuiGrid-item { width: auto; }
	.grm .MuiGrid-root.right-menu.MuiGrid-item { width: auto; }
	.hg .MuiGrid-root.right-menu.MuiGrid-item { width: auto; }
} 
@media (max-width:420px) { 
	.gr .MuiGrid-root.left-menu.MuiGrid-item { width: auto; }
	.gr .MuiGrid-root.left-menu.MuiGrid-item .MuiGrid-root.menu-link { margin: 0 auto; display: table; float: none; }
	.gr .MuiGrid-root.left-menu.MuiGrid-item .MuiGrid-root.menu-link link { margin: 0 auto; display: table; float: none; }
	.sp .sp-none, .rus .sp-none, .hg .sp-none { display: none; }
	a.MuiButtonBase-root.MuiButton-root.MuiButton-text.sp-show { font-size: 15px; font-weight: 600; margin: 0 auto; display: table;}
 }

/** Lang CSS End */


@media (max-width : 1055px) { header.home-header { position: relative !important ; }}

@media (min-width:320px) and (max-width:1222px) { 
	.MuiGrid-root.login-box.MuiGrid-item { order: 1; margin-left: auto; order: unset; }
	.MuiGrid-root.menu-space.MuiGrid-item { width: 100%; padding-top: 0; }	
	.MuiGrid-root.menu-link { margin: 0; float: left; }
	.MuiGrid-root.right-menu.MuiGrid-item .MuiGrid-root.menu-link { float: right; }	
}


// @media (min-width:320px) and (max-width:1055px) {
	
	
//  }

 @media (max-width:991px) {
	.MuiTypography-root.slider-main-title { text-align: center; font-size: 25px; line-height: 35px; margin-bottom: 15px; margin-top: 15px; }
	.MuiGrid-root.title-upper-line { height: 5px; max-width: 290px; margin: 0 auto 25px; }
	.MuiTypography-root.slider-inner-title { max-width: 100%; text-align: center; }
	.get-started-btn { margin: 20px auto 0; float: none; display: table; text-align: center; }
	.mobile-space { padding: 90px 0 50px; }
	.mobile-inner-pad { padding: 30px 0 !important; }
	.cstm-pad { padding: 40px 0 !important;}
	.MuiGrid-root.makeStyles-bgWhite-3.mobile-inner-pad { padding: 30px 15px !important;}
	.MuiTypography-root.main-title-full { text-align: center; font-size: 32px; line-height: 45px; margin-bottom: 15px; }
	.main-title-full.text-left.ab-title { font-size: 32px; }
	.MuiTypography-root.main-title-full-inner { text-align: center; font-size: 16px; }
	.order-2 { order: 0 ; margin-bottom: 15px; }
	.center-mobile-big { max-width: 300px; }
	.MuiGrid-root.storebox img { max-width: 170px;}
	ul.main-tab li { padding: 13px 16px; }
  }

  @media ( max-width: 991px) { 
	.MuiGrid-root.search-map { max-width: 100%; position: relative; left: 0; width: calc(100% - 30px); top: 0; margin: 15px; }
	.MuiGrid-root.search-map.search-map-bitcoin_atms.search-map-home { margin-top: 40px; }
	.mobile-text-center { text-align: center;}
	.MuiTypography-root.main-title { font-size: 35px;}
  }

  @media (max-width: 960px) {
	.MuiGrid-root.home-tabs { margin-top: 25px; }
	.hide-mobile { display: none; }
	.MuiGrid-root.Personal-account-box { margin-top: 0px; }
	img.center-mobile-big.center-mobile-bighand { margin-bottom: 30px; }
  }

 @media (min-width:511px) and (max-width:613px) {
	.MuiGrid-root.left-menu.MuiGrid-item, .MuiGrid-root.right-menu.MuiGrid-item { width: auto; }
	ul.main-tab li { padding: 13px 10px; }
	
 }

 @media (max-width:511px) { 	
	header .MuiButton-text.btn-user { margin-bottom: 5px; }
	.MuiGrid-root.new-main-tab.firstbox-tabs .box-select, .MuiGrid-root.new-main-tab.secondbox-tabs .box-select { border-radius: 29px 29px 0 0 !important;}
	button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-user.active-btn.homelang { float: left;  width: auto; }
	.MuiGrid-root.menu-link link { padding: 6px 0px; margin: 0 5px; }		
	// .MuiGrid-root.pull-right.top-space { margin-top: 0px;}
	.mobile-space.top-space { padding-top: 40px; }
	ul.main-tab li { width: 100%; margin-bottom: 10px; }
	.MuiTypography-root.slider-main-title.text-center.download-text { font-size: 25px; }
	.MuiGrid-root.storebox img { margin: -5px 4px 0; }
	.MuiGrid-root.footer { padding: 40px 0 20px; }
	.maxlength { width: 100% !important; }
	.maxlength .styles_react-code-input__CRulA > input { margin: 0 2px; height: 43px !important; }
	.maxlength { margin-top: 10px;}
	.MuiButton-text.tr-bg.back-to-signin { margin: 0 auto; display: table; padding: 0; }
	.MuiButton-text.Forget-Google-Authenticator { margin: 10px auto 0; float: none !important; display: table; padding: 0; }
	
	.MuiGrid-root.money-select.money_crypto { width: 100%; }
	.MuiGrid-root.money-select.money_crypto .box-select.iconbox.MuiFormControl-fullWidth{ background: #3C1360; border-radius: 29px 29px 0 0; color: #fff; }
	.MuiGrid-root.money-select.money_crypto + .MuiGrid-root.box-size { width: 100%; border-radius: 0 0 29px 29px; }
 }

 @media (max-width:767px) { 
	.MuiGrid-root.logo-box { width: 100%; padding: 12px 0 0 !important; }	
	.MuiGrid-root.login-box.MuiGrid-item { margin: 0 auto; padding-bottom: 5px; }
	.MuiGrid-root.logo-box img { max-width: 150px; margin: 0 auto; display: table; }
	.MuiGrid-root.left-menu.MuiGrid-item, .MuiGrid-root.right-menu.MuiGrid-item { width: auto; margin: 3px auto 0; float: none; display: table; }
	.MuiFormControl-root.position-select { width: 100%; margin-bottom: 5px; }
	.transaction_check.MuiFormControl-fullWidth { width: 100%; margin-left: 0; }
	.MuiButton-text.load-more1 { margin-left: 0px; width: 100%; margin-top: 10px; }
	.MuiGrid-root.box-size.fullWidth input { font-size: 15px; }
	.MuiGrid-root.storebox img { max-width: 130px; }
	.main-title.main-title-sub-main.mt-0.cstm-small-title { font-size: 30px; }
		
  }
  @media (max-width:360px) {  
	.MuiButton-text.language { min-width: 47px; }
	.MuiButton-text.btn-user { margin-right: 10px }
	.MuiContainer-root.header-space { padding-left: 6px; padding-right: 6px; }
	.MuiGrid-root.pull-right.top-space { margin-top: 0px; }
  }


/* Sidebar */
nav.pro-menu.new-menu { margin-top: 15px; }
nav.pro-menu li img + span { margin-top: 10px; float: left; color: #000; font-weight: 400; }
nav.pro-menu li img { margin-right: 10px; width: 28px; height: 28px; box-shadow: 2px 1px 12px rgb(0 0 0 / 20%); border-radius: 50%; margin: 8px; float: left; }
li.pro-menu-item { width: 100%; float: left; padding: 8px 0; padding-left: 45px; border-radius: 10px; margin-bottom: 5px; }
li.pro-menu-item.dashboard { background: url(../images/menuicon/dashboard-new.png) left 5px top 4px no-repeat !important; background-size: 33px !important; background-position: top 3px left 2px !important; }
li.pro-menu-item.administrator { background: url(../images/menuicon/profile-new.png) left 5px top 4px no-repeat !important; background-size: 33px !important; background-position: top 3px left 2px !important; }
li.pro-menu-item.requests { background: url(../images/menuicon/Requests-new.png) left 5px top 4px no-repeat !important; background-size: 33px !important; background-position: top 3px left 2px !important; }
li.pro-menu-item.accounts { background: url(../images/menuicon/account-new.png) left 5px top 4px no-repeat !important; background-size: 33px !important; background-position: top 3px left 2px !important; }
li.pro-menu-item.profiles { background: url(../images/menuicon/profile-new.png) left 5px top 4px no-repeat !important; background-size: 33px !important; background-position: top 3px left 2px !important; }
li.pro-menu-item.transactions { background: url(../images/menuicon/transactions-new.png) left 5px top 4px no-repeat !important; background-size: 33px !important; background-position: top 3px left 2px !important; }
li.pro-menu-item.messages { background: url(../images/menuicon/message-new.png) left 5px top 4px no-repeat !important; background-size: 33px !important; background-position: top 3px left 2px !important; }
li.pro-menu-item.system-logs { background: url(../images/menuicon/logs-new.png) left 5px top 4px no-repeat !important; background-size: 33px !important; background-position: top 3px left 2px !important; }
li.pro-menu-item.settings { background: url(../images/menuicon/settings-new.png) left 5px top 4px no-repeat !important; background-size: 33px !important; background-position: top 3px left 2px !important; }
li.pro-menu-item.reports { background: url(../images/menuicon/reports-new.png) left 5px top 4px no-repeat !important; background-size: 33px !important; background-position: top 3px left 2px !important; }
li.pro-menu-item.pages-settings { background: url(../images/menuicon/settings-new.png) left 5px top 4px no-repeat !important; background-size: 33px !important; background-position: top 3px left 2px !important; }
li.pro-menu-item.helpcenter { background: url(../images/menuicon/help-center-new.png) left 5px top 4px no-repeat !important; background-size: 33px !important; background-position: top 3px left 2px !important; }


li.pro-menu-item.active { background-color: #FFFFFF !important; border-radius: 10px; }
.pro-sidebar .pro-menu .pro-menu-item:hover, li.pro-menu-item.open { background-color: #fff !important; cursor: pointer; }

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul .pro-menu-item { margin-bottom: 0px; color: #fff !important; }
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul .pro-menu-item:hover { background-color: #DC9018 !important; text-decoration: underline; }
.react-slidedown.pro-inner-list-item li.pro-menu-item { background: url(../images/menuicon/arrow.png) left no-repeat; background-size: 13px !important; background-position: top 8px left 0px !important; padding: 3px 20px 3px 10px; }
li.pro-menu-item.pro-sub-menu .react-slidedown.pro-inner-list-item { height: 0; overflow: hidden; float: left; padding: 0; width: calc(100% + 45px); margin-left: -45px; margin-top: 8px; background: #DC9018; margin-bottom: -8px; }

.pro-sidebar { width: 100% !important; min-width: 100% !important;}
.pro-sidebar { color: #000; text-align: left; transition: width, left, right, 0.3s; position: relative; z-index: 1009; }
.pro-sidebar > .pro-sidebar-inner { background: unset; position: relative; z-index: 101; }
.pro-sidebar > .pro-sidebar-inner > img.sidebar-bg { width: 100%; object-fit: cover; object-position: center; position: absolute; opacity: 0.3; left: 0; top: 0; z-index: 100; }
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout { height: auto !important; overflow-y: auto; overflow-x: hidden; position: relative; display: flex; flex-direction: column; z-index: 101; }
// .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout { position: relative; display: flex; flex-direction: column; z-index: 101; }
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header { border-bottom: 1px solid rgba(173, 173, 173, 0.2); }
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content { flex-grow: 1; }
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer { border-top: 1px solid rgba(173, 173, 173, 0.2); }
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul { list-style-type: none; padding: 0; margin: 0; }
.pro-sidebar .overlay { position: fixed; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(0, 0, 0, 0.3); z-index: 100; display: none; }
.pro-sidebar.collapsed { width: 80px; min-width: 80px; }
.pro-sidebar.rtl { text-align: right; direction: rtl; }
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: unset !important;
}

@media (max-width: 480px) {
.pro-sidebar.xs { position: fixed; left: -270px; }
.pro-sidebar.xs.collapsed { left: -80px; }
.pro-sidebar.xs.toggled { left: 0; } 
.pro-sidebar.xs.toggled .overlay { display: block; }
.pro-sidebar.xs.rtl { left: auto; right: -270px; }
.pro-sidebar.xs.rtl.collapsed { left: auto; right: -80px; }
.pro-sidebar.xs.rtl.toggled { left: auto; right: 0; }
}
@media (max-width: 550px) { 
	button.sc-bczRLJ.eWjCzc.rec.rec-arrow.rec.rec-arrow-right, button.sc-bczRLJ.eWjCzc.rec.rec-arrow.rec.rec-arrow-left, .eAyARa:disabled { display: none; }
}

@media (max-width: 750px) {
	.MuiGrid-root.order-2-bottom { float: left; width: 100%; text-align: center; order: 2; }
	.MuiGrid-root.FullScreenLogo { margin: 0 auto 19px; }
 }

@media (max-width: 576px) {
.pro-sidebar.sm { position: fixed; left: -270px; }
.pro-sidebar.sm.collapsed { left: -80px; }
.pro-sidebar.sm.toggled { left: 0; }
.pro-sidebar.sm.toggled .overlay { display: block; }
.pro-sidebar.sm.rtl { left: auto; right: -270px; }
.pro-sidebar.sm.rtl.collapsed { left: auto; right: -80px; }
.pro-sidebar.sm.rtl.toggled { left: auto; right: 0; }
.MuiGrid-root.new-main-tab { width: 100% !important; margin-left: 0px !important; }
.MuiGrid-root.new-main-tab.secondbox-tabs { margin-top: 10px;}
.MuiGrid-root.home-tabs { padding: 15px; }
.MuiButtonGroup-root.Funding-limits button { min-width: auto; padding: 7px 15px; margin-top: 6px; margin-bottom: 10px; }
}

@media (max-width: 599px) {
	.MuiFormControl-root.cstm-autocomplete.MuiFormControl-fullWidth { width: 100% !important;}
	.AutoComplete-box { width: calc(100% - 0px) !important; }
	.MuiTypography-root.tocketbank { float: left; }
	.bdr-right { border: none; }
	p.MuiTypography-root.coin-price { border: none; text-align: left;}
	.credit-tabs-main a { padding: 13px 12px !important; }
}

@media (max-width: 959px) { 
	.MuiGrid-root.hrline { display: none; }
}

@media (max-width: 768px) {
.pro-sidebar.md { position: fixed; left: -270px; }
.pro-sidebar.md.collapsed { left: -80px; }
.pro-sidebar.md.toggled { left: 0; }
.pro-sidebar.md.toggled .overlay { display: block; }
.pro-sidebar.md.rtl { left: auto; right: -270px; }
.pro-sidebar.md.rtl.collapsed { left: auto; right: -80px; }
.pro-sidebar.md.rtl.toggled { left: auto; right: 0; }
}

@media (max-width: 992px) {
.pro-sidebar.lg {position: fixed; left: -270px; }
.pro-sidebar.lg.collapsed { left: -80px; }
.pro-sidebar.lg.toggled {left: 0; }
.pro-sidebar.lg.toggled .overlay {display: block; }
.pro-sidebar.lg.rtl { left: auto; right: -270px; }
.pro-sidebar.lg.rtl.collapsed {left: auto;right: -80px; }
.pro-sidebar.lg.rtl.toggled { left: auto; right: 0; }
}
	
@media (max-width: 1200px) {
.pro-sidebar.xl { position: fixed;left: -270px; }
.pro-sidebar.xl.collapsed { left: -80px; }
.pro-sidebar.xl.toggled { left: 0; }
.pro-sidebar.xl.toggled .overlay { display: block; }
.pro-sidebar.xl.rtl { left: auto;  right: -270px; }
.pro-sidebar.xl.rtl.collapsed { left: auto; right: -80px; }
.pro-sidebar.xl.rtl.toggled { left: auto; right: 0; } 
}

@media (max-width: 1600px) {
.pro-sidebar.xxl { position: fixed; left: -270px; }
.pro-sidebar.xxl.collapsed { left: -80px; }
.pro-sidebar.xxl.toggled {  left: 0; }
.pro-sidebar.xxl.toggled .overlay { display: block; }
.pro-sidebar.xxl.rtl {  left: auto;  right: -270px; }
.pro-sidebar.xxl.rtl.collapsed { left: auto; right: -80px; }
.pro-sidebar.xxl.rtl.toggled { left: auto; right: 0; } 
}

@media (min-width: 1279px) { 
	.MuiGrid-root.main-menu-box { padding: 0px 8px;}
}

@media (min-width: 991px) {
	p.MuiTypography-root.slider-main-title.new-top-crypto {
		margin-top: 25px;
	}
}

.MuiGrid-root.cstm-crypto-table.dark-table button.MuiButtonBase-root.MuiIconButton-root { color: #7e7d7d; }  
.pro-sidebar .pro-menu.submenu-bullets .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before { content: ''; display: inline-block; width: 4px; min-width: 4px; height: 4px; border: 1px solid #2b2b2b; border-radius: 50%; margin-right: 15px; position: relative; box-shadow: 1px 0px 0px #adadad, 0px -1px 0px #adadad, 0px 1px 0px #adadad, -1px 0px 0px #adadad; }
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
.pro-sidebar .pro-menu { padding-top: 10px; padding-bottom: 10px; }
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item { position: relative; background-color: #DC9018; }
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul { float: left; width: 100%; padding: 10px 0;}
.pro-sidebar .pro-menu a { text-decoration: none;  color: #000; }
.pro-sidebar .pro-menu a:before { content: ''; position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-color: transparent; }
.pro-sidebar .pro-menu a:hover { color: #000; }
.pro-sidebar .pro-menu .pro-menu-item { font-size: 14px; color: #000; font-weight: 400; overflow: hidden; }
.pro-sidebar .pro-menu .pro-menu-item.active { color: #000; }
.pro-sidebar .pro-menu .pro-menu-item .suffix-wrapper { opacity: 1; transition: opacity 0.2s; }
.pro-sidebar .pro-menu .pro-menu-item .prefix-wrapper { display: flex; margin-right: 5px; opacity: 1; transition: opacity 0.2s; }
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item { position: relative; display: flex; align-items: center; padding: 0; cursor: pointer; }
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {  outline: none;  color: #000; }
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper { margin-right: 10px; font-size: 14px; width: 35px; min-width: 35px; height: 35px; line-height: 35px; text-align: center; display: inline-block; }
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper .pro-icon { display: flex; height: 100%; align-items: center; justify-content: center; }
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content { flex-grow: 1; flex-shrink: 1; }
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover .pro-icon-wrapper .pro-icon { animation: swing ease-in-out 0.5s 1 alternate; }
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item:before { background: #000; }
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper { position: absolute; right: 20px; top: 50%; transform: translateY(-50%); }
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow { display: inline-block; border-style: solid; border-color: #000; border-width: 0 2px 2px 0; padding: 2.5px; vertical-align: middle; transition: transform 0.3s; transform: rotate(-45deg); }
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item:before { background: transparent !important; }
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow { transform: rotate(45deg); }
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item > .pro-icon-wrapper { background: none; width: auto; min-width: auto; height: auto; line-height: auto; }
.pro-sidebar .pro-menu:not(.inner-submenu-arrows) .pro-inner-list-item .pro-menu-item.pro-sub-menu .pro-inner-item .pro-arrow-wrapper { display: none; }
.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper { background-color: #2b2b2b; }
.pro-sidebar .pro-menu.square .pro-menu-item > .pro-inner-item > .pro-icon-wrapper { border-radius: 0; }
.pro-sidebar .pro-menu.round .pro-menu-item > .pro-inner-item > .pro-icon-wrapper { border-radius: 4px; }
.pro-sidebar .pro-menu.circle .pro-menu-item > .pro-inner-item > .pro-icon-wrapper { border-radius: 50%; }
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item { padding: 0px 0px 0 12px !important; }
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item { position: relative; }
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-item > .suffix-wrapper, .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-item > .prefix-wrapper { opacity: 0; }
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-list-item { background-color: #2b2b2b; z-index: 111; }
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item::before { content: ''; display: inline-block; position: absolute; top: 0; bottom: 0; left: 0; right: 0; cursor: pointer; }
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu { position: relative; }
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-item { pointer-events: none; }
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper { display: none; }
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item { height: auto !important; position: fixed; visibility: hidden; min-width: 220px; max-width: 270px; background-color: transparent; max-height: 100%; padding-left: 3px; }
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item.has-arrow { padding-left: 10px; }
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner { max-height: 100vh; overflow-y: auto; background-color: #2b2b2b; padding-left: 20px; border-radius: 4px; } 
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:hover > .pro-inner-list-item { transition: visibility, transform 0.3s; visibility: visible; }
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:hover .pro-icon-wrapper .pro-icon { animation: swing ease-in-out 0.5s 1 alternate; }
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item, .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item { padding: 8px 30px 8px 5px; }
.pro-sidebar.rtl .pro-menu .pro-menu-item .prefix-wrapper { margin-right: 0; margin-left: 5px; }
.pro-sidebar.rtl .pro-menu .pro-menu-item > .pro-inner-item { padding: 8px 20px 8px 35px; }
.pro-sidebar.rtl .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper { margin-right: 0; margin-left: 10px; }
.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper { right: auto; left: 20px; }
.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow { transform: rotate(135deg); }
.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow { transform: rotate(45deg); }
.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item { padding-left: 0; padding-right: 24px; }
.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item { padding: 8px 15px 8px 30px; }
.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before { margin-right: 0; margin-left: 15px; }
.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item { padding-left: 0; padding-right: 3px; }
.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item.has-arrow { padding-right: 10px; }
.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner { padding-left: 0; padding-right: 20px; }
.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item, .pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item { padding: 8px 5px 8px 30px; }
.popper-arrow { position: absolute; z-index: -1; width: 0; height: 0; border-top: 7px solid transparent; border-bottom: 7px solid transparent; }
.popper-element[data-popper-placement^='left'] > .popper-arrow { right: 0; border-right: 7px solid #2b2b2b; }
.popper-element[data-popper-placement^='right'] > .popper-arrow { left: 0; border-left: 7px solid #2b2b2b; }
  
.react-slidedown { height: 0; transition-property: none; transition-duration: 0.2s; transition-timing-function: ease-in-out; }
.react-slidedown.transitioning { overflow-y: hidden; }
.react-slidedown.closed { display: none; }

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul .pro-inner-item:focus { color: #fff; text-decoration: none; }

.pro-sidebar.dark-mode span.pro-item-content { color: #fff; }
.pro-sidebar.dark-mode span.pro-arrow { border-color: #fff !important; }
.pro-sidebar.dark-mode .pro-menu .pro-menu-item:hover, .pro-sidebar.dark-mode li.pro-menu-item.open { background-color: #000000 !important; }

/* New Menu */
.MuiGrid-root.NewCstm_menu { width: 100%; padding-left: 0; padding-top: 10px; padding-right: 0px; }
.btnMenu { display: none; padding: 20px; display: block; background: #FFFFFF; color: #EEF9FE; }
.contenedor-menu { width: 100%; display: inline-block; line-height: 18px; }
.contenedor-menu .menu { width: 100%; margin: 0px; padding: 0px; }
.contenedor-menu ul { list-style: none; }
.contenedor-menu .menu li { margin-bottom: 5px; }
.contenedor-menu .menu li a { color: #000; display: block; padding: 12px 20px 12px 48px; border-radius: 5px; cursor: pointer; font-weight: 400; text-decoration: none; }
.contenedor-menu .menu li a:hover { color: #000; } 
.contenedor-menu .menu ul { height: 0px; overflow: hidden; }
.contenedor-menu .menu ul li a { background: #FFFFFF; color: #fff; text-decoration: none;  }
.contenedor-menu .menu ul li a:hover { color: #fff; text-decoration: underline;}
.contenedor-menu .menu .active > a { color: #000; }
.contenedor-menu .menu li.active ul { height: auto; margin: 0px; padding: 0px; padding-bottom: 10px;  transition: all .250s ease; }
.contenedor-menu .menu li.active ul { background: #DC9018; padding-top: 10px; border-radius: 0 0 8px 8px; overflow: hidden; }
.contenedor-menu .menu li.dashboard { background: url(../images/menuicon/dashboard-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px; }
.contenedor-menu .menu li.active.dashboard, .contenedor-menu .menu li.dashboard:hover { background: url(../images/menuicon/dashboard-new.png) left 2px top 4px no-repeat #fff !important; background-size: 33px !important; background-position: top 4px left 7px !important; }
.contenedor-menu .menu li.administrator { background: url(../images/menuicon/profile-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px; }
.contenedor-menu .menu li.active.administrator, .contenedor-menu .menu li.administrator:hover { background: url(../images/menuicon/profile-new.png) left 2px top 4px no-repeat #fff !important; background-size: 33px !important; background-position: top 4px left 7px !important; }
.contenedor-menu .menu li.requests { background: url(../images/menuicon/Requests-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px; }
.contenedor-menu .menu li.active.requests, .contenedor-menu .menu li.requests:hover { background: url(../images/menuicon/Requests-new.png) left 2px top 4px no-repeat #fff !important; background-size: 33px !important; background-position: top 4px left 7px !important;}
.contenedor-menu .menu li.accounts { background: url(../images/menuicon/account-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px;}
.contenedor-menu .menu li.active.accounts, .contenedor-menu .menu li.accounts:hover { background: url(../images/menuicon/account-new.png) left 2px top 4px no-repeat #fff !important; background-size: 33px !important; background-position: top 4px left 7px !important; }
.contenedor-menu .menu li.profiles { background: url(../images/menuicon/profile-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px; }
.contenedor-menu .menu li.active.profiles,.contenedor-menu .menu li.profiles:hover { background: url(../images/menuicon/profile-new.png) left 2px top 4px no-repeat #fff !important; background-size: 33px !important; background-position: top 4px left 7px !important; }
.contenedor-menu .menu li.transactions { background: url(../images/menuicon/transactions-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px; }
.contenedor-menu .menu li.active.transactions, .contenedor-menu .menu li.transactions:hover { background: url(../images/menuicon/transactions-new.png) left 2px top 4px no-repeat #fff !important; background-size: 33px !important; background-position: top 4px left 7px !important;}
.contenedor-menu .menu li.messages { background: url(../images/menuicon/message-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px; }
.contenedor-menu .menu li.active.messages, .contenedor-menu .menu li.messages:hover { background: url(../images/menuicon/message-new.png) left 2px top 4px no-repeat  #fff !important; background-size: 33px !important; background-position: top 4px left 7px !important; }
.contenedor-menu .menu li.system-logs { background: url(../images/menuicon/logs-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px; }
.contenedor-menu .menu li.system-logs.active, .contenedor-menu .menu li.system-logs:hover { background: url(../images/menuicon/logs-new.png) left 2px top 4px no-repeat #fff !important; background-size: 33px !important; background-position: top 4px left 7px !important; }
.contenedor-menu .menu li.settings { background: url(../images/menuicon/settings-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px; }
.contenedor-menu .menu li.active.settings, .contenedor-menu .menu li.settings:hover { background: url(../images/menuicon/settings-new.png) left 2px top 4px no-repeat #fff !important; background-size: 33px !important; background-position: top 4px left 7px !important; }
.contenedor-menu .menu li.reports { background: url(../images/menuicon/reports-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px; }
.contenedor-menu .menu li.active.reports, .contenedor-menu .menu li.reports:hover { background: url(../images/menuicon/reports-new.png) left 2px top 4px #fff no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; }
.contenedor-menu .menu li.helpcenter { background: url(../images/menuicon/help-center-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px; }
.contenedor-menu .menu li.active.helpcenter, .contenedor-menu .menu li.helpcenter:hover { background: url(../images/menuicon/help-center-new.png) left 2px top 4px no-repeat #fff !important; background-size: 33px !important; background-position: top 4px left 7px !important; }
.contenedor-menu .menu li ul li a { background: url(../images/menuicon/arrow.png) left no-repeat; background-size: 13px !important; background-position: top 8px left 16px !important; padding: 5px 5px 5px 43px !important; }
.MuiDrawer-root.MuiDrawer-docked > div { background: #EEF9FE; }
.contenedor-menu .menu li.submenu > a::after { border: solid black; border-width:0 2px 2px 0; display: inline-block; float: right; margin-top: 3px; padding: 3px; transform: rotate(45deg); -webkit-transform: rotate(45deg); content: "";  }
.contenedor-menu .menu li.submenu > a:hover::after { border-color: #000; }
.contenedor-menu .menu li.submenu.active > a::after { border-color: #000; transform: rotate(226deg); -webkit-transform: rotate(226deg); margin-top: 5px; }

// iframe { z-index: -11111111111111 !important;}

.dark-mode .contenedor-menu .menu li a { color: #fff; }
.dark-mode .contenedor-menu .menu li.dashboard { background: url(../images/menuicon/dashboard-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px; }
.dark-mode .contenedor-menu .menu li.active.dashboard, .dark-mode .contenedor-menu .menu li.dashboard:hover { background: url(../images/menuicon/dashboard-new.png) left 2px top 4px no-repeat #000 !important; background-size: 33px !important; background-position: top 4px left 7px !important; }
.dark-mode .contenedor-menu .menu li.administrator { background: url(../images/menuicon/profile-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px; }
.dark-mode .contenedor-menu .menu li.active.administrator, .dark-mode .contenedor-menu .menu li.administrator:hover { background: url(../images/menuicon/profile-new.png) left 2px top 4px no-repeat #000 !important; background-size: 33px !important; background-position: top 4px left 7px !important; }
.dark-mode .contenedor-menu .menu li.requests { background: url(../images/menuicon/Requests-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px; }
.dark-mode .contenedor-menu .menu li.active.requests, .dark-mode .contenedor-menu .menu li.requests:hover { background: url(../images/menuicon/Requests-new.png) left 2px top 4px no-repeat #000 !important; background-size: 33px !important; background-position: top 4px left 7px !important;}
.dark-mode .contenedor-menu .menu li.accounts { background: url(../images/menuicon/account-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px;}
.dark-mode .contenedor-menu .menu li.active.accounts, .dark-mode .contenedor-menu .menu li.accounts:hover { background: url(../images/menuicon/account-new.png) left 2px top 4px no-repeat #000 !important; background-size: 33px !important; background-position: top 4px left 7px !important; }
.dark-mode .contenedor-menu .menu li.profiles { background: url(../images/menuicon/profile-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px; }
.dark-mode .contenedor-menu .menu li.active.profiles, .dark-mode .contenedor-menu .menu li.profiles:hover { background: url(../images/menuicon/profile-new.png) left 2px top 4px no-repeat #000 !important; background-size: 33px !important; background-position: top 4px left 7px !important; }
.dark-mode .contenedor-menu .menu li.transactions { background: url(../images/menuicon/transactions-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px; }
.dark-mode .contenedor-menu .menu li.active.transactions, .dark-mode .contenedor-menu .menu li.transactions:hover { background: url(../images/menuicon/transactions-new.png) left 2px top 4px no-repeat #000 !important; background-size: 33px !important; background-position: top 4px left 7px !important;}
.dark-mode .contenedor-menu .menu li.messages { background: url(../images/menuicon/message-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px; }
.dark-mode .contenedor-menu .menu li.active.messages, .dark-mode .contenedor-menu .menu li.messages:hover { background: url(../images/menuicon/message-new.png) left 2px top 4px no-repeat  #000 !important; background-size: 33px !important; background-position: top 4px left 7px !important; }
.dark-mode .contenedor-menu .menu li.system-logs { background: url(../images/menuicon/logs-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px; }
.dark-mode .contenedor-menu .menu li.system-logs.active, .dark-mode .contenedor-menu .menu li.system-logs:hover { background: url(../images/menuicon/logs-new.png) left 2px top 4px no-repeat #000 !important; background-size: 33px !important; background-position: top 4px left 7px !important; }
.dark-mode .contenedor-menu .menu li.settings { background: url(../images/menuicon/settings-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px; }
.dark-mode .contenedor-menu .menu li.active.settings, .dark-mode .contenedor-menu .menu li.settings:hover { background: url(../images/menuicon/settings-new.png) left 2px top 4px no-repeat #000 !important; background-size: 33px !important; background-position: top 4px left 7px !important; }
.dark-mode .contenedor-menu .menu li.reports { background: url(../images/menuicon/reports-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px; }
.dark-mode .contenedor-menu .menu li.active.reports, .dark-mode .contenedor-menu .menu li.reports:hover { background: url(../images/menuicon/reports-new.png) left 2px top 4px #000 no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; }
.dark-mode .contenedor-menu .menu li.helpcenter { background: url(../images/menuicon/help-center-new.png) left 2px top 4px no-repeat !important; background-size: 33px !important; background-position: top 4px left 7px !important; border-radius: 10px; }
.dark-mode .contenedor-menu .menu li.active.helpcenter, .dark-mode .contenedor-menu .menu li.helpcenter:hover { background: url(../images/menuicon/help-center-new.png) left 2px top 4px no-repeat #000 !important; background-size: 33px !important; background-position: top 4px left 7px !important; }
.dark-mode .contenedor-menu .menu li.submenu > a::after { border: solid #fff; border-width: 0 2px 2px 0; }

.MuiFormControl-root.cstm-line-select { border: 1px solid #C4C4C4; border-radius: 5px; width: 100%; }
.MuiFormControl-root.cstm-line-select .MuiInput-underline:before, .MuiFormControl-root.cstm-line-select .MuiInput-underline:after { border: none !important;}
.MuiFormControl-root.cstm-line-select .MuiSelect-outlined.MuiSelect-outlined { padding-top: 18px; padding-bottom: 18px; padding-left: 18px; }
li.MuiButtonBase-root.MuiMenuItem-root.Mui-selected.Mui-selected { background: rgba(0, 0, 0, 0.1); border-radius: 4px !important; margin-bottom: 2px; margin-left: 4px; margin-top: 2px; }

ul.credit-tabs-main { margin: 5px 0 15px 0; padding: 0; float: left; width: 100%; }
.align-center-box { width: 100%; float: left; } 
ul.credit-tabs-main li { float: left; list-style: none; background: #E4E4E4; color: #000; padding: 11px 17px; font-size: 17px; border-radius: 5px; margin-right: 15px; cursor: pointer; }
ul.credit-tabs-main li.react-tabs__tab--selected { background: #723789; color: #fff; }
.credit-tabs.dark ul.credit-tabs-main li.react-tabs__tab--selected { background: #fff; color: #000; }
.credit-tabs.dark ul.credit-tabs-main li { background: #272727; color: #fff; }
.inner-credit-tabs li { float: left; list-style: none; background: #E4E4E4; width: calc(50% - 25px); color: #000; padding: 11px 25px; cursor: pointer; border: 2px solid #000; border-radius: 5px; text-transform: capitalize; min-width: 90px; margin-right: 25px; font-size: 18px; text-align: center; }
ul.inner-credit-tabs li.react-tabs__tab--selected { background: #000000; color: #fff; }
.MuiGrid-root.inner-credit-tabs { float: left; width: 100%; margin: 0; padding: 0; }
.credit-tabs .inner-credit-tabs li.react-tabs__tab--selected { background: #000; color: #fff; }
.credit-tabs ul.inner-credit-tabs li { background: #272727; color: #fff; }
.MuiGrid-root.inner-credit-tabs ul.react-tabs__tab-list { float: left; width: 100%; margin: 0; padding: 0; }
.MuiGrid-root.Limits-infi { margin-top: 15px; margin-bottom: 0; margin-top: 0px; border-radius: 5px; padding: 10px;  background: #DDDDDD; text-align: center; }
.MuiGrid-root.Limits-infi p { font-size: 14px; padding: 2px 0; color: #000; }
.MuiGrid-root.level-type img.center-img-increase { max-width: 100%; margin: 0 auto 5px; display: table; width: 100%; }
.MuiGrid-root.containerspace.MuiGrid-container.MuiGrid-spacing-xs-1 { margin: 0 auto; max-width: 840px; width: 100%; }
.MuiGrid-root.containerspace.MuiGrid-container.MuiGrid-spacing-xs-1 .MuiGrid-grid-xs-12.MuiGrid-grid-md-6 { padding: 0 25px; }
p.MuiTypography-root.Limits-title { border: none; font-size: 16px !important; margin-bottom: 5px !important; font-weight: 600; }
.Modalcstm-bg .MuiGrid-root.Limits-box-inner p.MuiTypography-root { font-size: 13px; }
button.MuiButtonBase-root.Listsuccessbtn { background: #00A65A !important; font-size: 15px; font-weight: 600; padding: 7px 9px; min-height: auto; line-height: 17px; min-width: auto }
button.MuiButtonBase-root.Listdangerbtn { background: #DD4B39 !important; font-size: 15px; font-weight: 600; padding: 7px 9px; min-height: auto; line-height: 17px; min-width: auto }
button.MuiButtonBase-root.Listsuccessbtnblue { background: #0096FF !important; font-size: 15px; font-weight: 600; padding: 7px 9px; min-height: auto; line-height: 17px; min-width: auto }
.MuiGrid-root.light-table svg.MuiSvgIcon-root.action-link { color: #000; margin: 0 5px; }
.MuiGrid-root.dark-table svg.MuiSvgIcon-root.action-link { color: #fff;  margin: 0 5px; }
.MuiPaper-root.MuiCard-root.dropdwon-pad { padding: 0; }
.MuiGrid-root.dropdwon-pad-data.MuiGrid-item { padding: 20px; }
.MuiGrid-root.dropdwon-pad-data.MuiGrid-item p.MuiTypography-root.order-title{ font-size: 17px;}
p.MuiTypography-root.Estimate-time.red-highlight { opacity: 1; color: red; }
.MuiGrid-root.level-type.level-type-info.level.active { background-position: right 10px top 8px; background-size: 7%; }
.MuiGrid-root.dropdwon-pad-data.MuiGrid-item .MuiSelect-root.MuiSelect-select { min-width: 272px; }
.MuiPaper-root.MuiCard-root.dropdwon-pad.MuiPaper-rounded .MuiPaper-root { box-shadow: none; }
.action-link.red-highlight { color: red; }
p.MuiTypography-root.below-button { margin-left: -18px; font-size: 16px; color: #DC9018; margin-top: -17px; }
p.MuiTypography-root.below-button + a.MuiButton-text.btn.btn-primary.link-btn { margin-left: -17px !important; width: auto !important; }
.MuiDialog-root.dialogs-msg-popup .MuiDialog-paperWidthSm { width: 100% !important; } 
.MuiDialog-root.dialogs-msg-popup .danger { color: #f50808; font-size: 19px; text-align: center; margin-top: 20px; font-weight: 500; }
.MuiDialog-root.dialogs-msg-popup .success { color: #2d8d21; font-size: 19px; text-align: center; margin-top: 20px; font-weight: 500; }
.MuiDialog-root.dialogs-msg-popup .MuiDialog-paperWidthSm { max-width: 430px;}
.MuiGrid-root.br {float: left;width: 100%;margin: 20px 0;background: #000;height: 2px;}
.rm-div > div { opacity: 1 !important; transform: none !important; }
p.MuiTypography-root.slider-main-title.text-left-new.blog-title-new { font-size: 25px; line-height: 35px; }
.MuiGrid-root.news-date.blog-date-new.MuiGrid-item { margin-bottom: 8px !important; float: left; width: 100%; font-size: 16px; }
p.MuiTypography-root.main-title-full-inner.text-left-new.blog-short-new { font-weight: 600; font-size: 17px; }
p.MuiTypography-root.main-title-full-inner.text-left-new.blog-long-new { font-size: 17px; font-weight: 500; }

button.sc-bczRLJ.eWjCzc.rec.rec-arrow.rec.rec-arrow-right { background: transparent !important; box-shadow: none !important; font-size: 35px !important; text-indent: 3px; }
button.sc-bczRLJ.eWjCzc.rec.rec-arrow.rec.rec-arrow-left { background: transparent !important; box-shadow: none !important; font-size: 35px !important; text-indent: -3px; }
button.sc-bczRLJ.eWjCzc.rec.rec-arrow.rec.rec-arrow-right:hover, button.sc-bczRLJ.eWjCzc.rec.rec-arrow.rec.rec-arrow-left:hover { background: #DC9018 !important; color: #fff !important; }
button.sc-bczRLJ.eWjCzc.rec.rec-arrow.rec.rec-arrow-right:focus, button.sc-bczRLJ.eWjCzc.rec.rec-arrow.rec.rec-arrow-left:focus { color: #333 !important; }
.krmNah { box-shadow: 0 0 1px 2px #0a0c0d !important; background-color: #DC9018 !important; }
.krmNah:hover, .krmNah:focus { box-shadow: 0 0 1px 2px #0a0c0d !important; }
.eAyARa:disabled { opacity: 0;}
.jhmYzC:hover, .jhmYzC:focus { box-shadow: 0 0 1px 2px #0a0c0d !important; }
.MuiGrid-root.align-center.dark1.dark-home-blog .about-box { background: #191919; }
.MuiGrid-root.align-center.dark1.dark-home-blog .about-box .eWjCzc { color: #666666; }
.mobile-inner-pad.p-15 { padding: 15px !important; }
.hHJTFD { margin: 0px !important;}
.MuiGrid-root.cstm-tabs.cstm-tabs-login .MuiBox-root { padding: 0 15px; }
.MuiFormControl-root.cstm-autocomplete.MuiFormControl-fullWidth { margin-top: 16px; width: calc(100% - 15px);}
.Forget2FaBox { padding: 0 28px; }
.AutoComplete-box { width: calc(100% - 15px); }
.MuiGrid-root.BoxLogin.MuiGrid-item { max-width: 510px; width: calc(100% - 30px); }
.MuiGrid-root.cstm-tabs { margin-top: 14px; }
.MuiAutocomplete-popper { border: 2px solid #DC9018; border-radius: 3px; }
p.MuiTypography-root.available-balance-new { margin-bottom: 5px; margin-top: 5px; font-size: 16px; float: left; padding: 8px 17px; border: 1px solid #e5e0e0; margin-right: 15px; border-radius: 5px; }
.MuiGrid-root.available-balance-grid.MuiGrid-item.MuiGrid-grid-xs-12 { border-bottom: 2px solid #DF9011; padding-bottom: 20px; margin-bottom: 20px; padding-left: 0; padding-right: 0; margin-left: 10px; margin-right: 10px; }
.MuiButton-text.resend-link-btn { margin-top: 6px; background: transparent; color: #000 !important; box-shadow: none ; font-weight: 600; font-size: 16px; }
.MuiButton-text.resend-link-btn:hover { background: transparent; color: #DC9018 !important;}
.light-tablecontent .mobile-inner-pad.atm_Details_new { padding: 0px 15px 15px !important; }

.MuiGrid-root.type-file input { margin-top: -5px; }
p.MuiTypography-root.file-page-title-name { font-size: 20px; margin-top: 0px; font-weight: 600; margin-bottom: 0px; }
.MuiPaper-root.none-width .MuiGrid-root.p-20 { max-width: 380px; margin: 0 auto; }
.MuiGrid-root.level-type.level-type-info.level { max-width: 380px; margin: 0 auto 8px; display: table; float: none; }

.f-right + h3, .right-button.f-righ + h3 { margin-top: 12px; font-weight: 500; margin-bottom: -4px; font-size: 1.4rem; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
p.MuiTypography-root.security-link { font-size: 21px; margin-top: 20px; word-break: break-all; }
.MuiGrid-root.order-desktop-2 { order: 2; }
.bottom8-dashboard { margin-bottom: 8px !important; }

a.MuiTypography-root.wallet-link.textbdr { margin-top: 20px; border-top: 1px solid #C5C3C3; padding-top: 7px; }

@media (min-width: 600px) and (max-width:1280px) {
	.MuiGrid-root.dashboard-order-2 { order: 2; }
	.MuiGrid-root.dashboard-order-3 { order: 3;}
}

@media (max-width: 959px) {
	.MuiGrid-root.bdr-table-head, .MuiGrid-root.bdr-table { display: none; }
	.crypto-table.new-box-dash, .Accountbox.new-box-dash-head { padding-left: 0px; }
	.MuiGrid-root.eur-balance { margin-bottom: 10px;}
}

	
.MuiGrid-root.new-main-tab.firstbox-tabs .box-select { width: 100%; background: #3C1360; border-radius: 29px 0 0 29px; color: #fff; }
.MuiGrid-root.new-main-tab.firstbox-tabs .MuiGrid-root.money-select .MuiSelect-select.MuiSelect-select, .MuiGrid-root.new-main-tab.secondbox-tabs .MuiGrid-root.money-select .MuiSelect-select.MuiSelect-select { color: #fff; padding-left: 15px; }
.MuiGrid-root.new-main-tab.firstbox-tabs input, .MuiGrid-root.new-main-tab.secondbox-tabs input { background: #E1E1E1; }
.MuiGrid-root.new-main-tab.secondbox-tabs .box-select { background: #3C1360; border-radius: 29px 0 0 29px; color: #fff; }
.MuiGrid-root.new-main-tab.firstbox-tabs .MuiOutlinedInput-input, .MuiGrid-root.new-main-tab.secondbox-tabs .MuiOutlinedInput-input { padding: 12.5px 14px; }
.MuiGrid-root.new-main-tab.sub.bg-dark-new-tabs input { background: #E1E1E1; border-radius: 29px; margin-top: 15px; text-align: left; }
button.MuiButton-text.get-started-btn.continue-cstm-tabs-btn, a.get-started-btn.continue-cstm-tabs-btn { width: auto; border-radius: 29px; min-width: 180px; margin: 10px auto 3px; float: none; display: table; background: #F28A11; padding: 13px 15px; font-size: 18px; font-weight: 500; color: #fff; text-align: center; }

.MuiGrid-root.ttabs { max-width: 550px; margin: 0 auto; }
.credit-tabs.light { width: 100%; }
.MuiGrid-root.cstm-tabs-data { float: left; width: 100%; margin: 18px 0; }
.MuiGrid-root.tabs-data { float: left; width: calc(100% - 150px); }
.MuiGrid-root.tabs-btn { float: left; width: 150px; }
// .MuiPaper-rounded { overflow: hidden; }

@media (max-width: 1310px) {
	.MuiGrid-root.tabs-data, .MuiGrid-root.tabs-btn { width: 100% !important; }
	.MuiGrid-root.order-2-new button { margin: 27px auto 0; min-width: 150px; }
 }

.none-hidden-overflow .MuiPaper-rounded { box-shadow: 0 4px 6px -2px rgb(0 0 0 / 12%), 0 2px 2px -1px rgb(0 0 0 / 5%) !important;}
p.MuiTypography-root.do_you_need { text-align: center; font-size: 27px; font-weight: 500; }
p.MuiTypography-root.do_you_need_value { text-align: center; font-weight: 600; font-size: 25px; margin-bottom: 25px; }
p.MuiTypography-root.do_you_need_email { text-align: center; font-weight: 500; font-size: 25px; }
p.MuiTypography-root.do_you_need_email span { font-weight: 600; word-break: break-all; }
.MuiGrid-root.captcha { margin: 25px auto; text-align: center; float: none; display: table; }
.MuiGrid-root.captcha p { font-weight: 600; font-size: 25px; }
.mobile-space.top-space.tablet-shadow .home-tabs { margin: 50px auto 0; }
.tablet-shadow-div { margin: 0 auto;}
img.qr-small { max-width: 23px; position: absolute; right: 19px; top: 11px; cursor: pointer; }
img.qr-small-2 { max-width: 23px; position: absolute; right: 19px; top: 24px; }
img.qr-small-3 { max-width: 23px; position: absolute; right: 19px; top: 25px; }
svg.qr-small { max-width: 23px; position: absolute; right: 19px; top: 11px; cursor: pointer; }
.MuiGrid-root.crypto_page { text-align: center; }
.MuiGrid-root.crypto_page .MuiGrid-root.title-upper-line { margin: 30px auto 20px; }
.MuiGrid-root.crypto_page .MuiTypography-root.slider-main-title.text-uppercase { font-size: 52px; line-height: 45px; margin-bottom: 6px; text-transform: inherit; font-weight: 500; }
.MuiGrid-root.crypto_page .MuiTypography-root.slider-inner-title { text-align: center; }
.MuiGrid-root.crypto_page .MuiTypography-root.slider-inner-title_new { text-align: center; font-size: 24px; font-weight: 500; margin: 15px auto 0; max-width: 100%; }
.home-tabs-black .MuiGrid-root.light-bg { background: #121213; }
.MuiGrid-root.light-bg { background: #E1E1E1; border-radius: 15px; margin-bottom: 15px !important; }
.centerFirstTab input { text-align: center !important; margin-top: 0px !important; margin-bottom: 10px; }
.MuiGrid-root.shadow-tabs-new { box-shadow: 0px 10px 15px 3px #979797; border-radius: 25px; }
.MuiGrid-root.shadow-tabs-new.dark { box-shadow: 0px 10px 15px 3px #262626; }
p.MuiTypography-root.succesfully_order { text-align: center; font-size: 29px; color: #1da343; font-weight: 600; }
p.MuiTypography-root.succesfully_order_address { text-align: center; font-size: 21px; font-weight: 500; margin-top: 4px; margin-bottom: 30px; }

p.MuiTypography-root.succesfully_order_id { text-align: center; font-weight: 600; font-size: 20px; margin: 9px 0;}
p.MuiTypography-root.succesfully_order_id span { font-weight: 500; }

.MuiGrid-root.desposit-address { float: left; width: 100%; text-align: center; margin-top: 25px; }
.MuiGrid-root.desposit-address p.MuiTypography-root { text-align: center; font-weight: 500; font-size: 19px; margin: 9px 0 0; }
a.MuiTypography-root.address-link { text-align: center; font-weight: 500; font-size: 18px; color: #3390EC; }
a.MuiTypography-root.wallet-link { margin-top: 5px; float: left; width: 100%; text-align: center; color: #242c3a; text-decoration: none; font-weight: 400; }
.one-line-bd { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
.home-tabs-black a.MuiTypography-root.wallet-link.textbdr { color: #757576; }

.MuiTypography-root.fiexed-types-title { float: left; width: 100%; font-size: 21px; font-weight: 600; padding-left: 0; margin-bottom: 10px; }
.MuiGrid-root.fullwidthnew.newTableBox tr, .MuiGrid-root.fullwidthnew.newTableBox .MuiTableHead-root { background: transparent; }

.tabs-business-inner a.Basicactive { background: #DC9018; }
.tabs-business-inner a { float: left; width: 100%; background: #2D2D2D; color: #fff; font-size: 18px; }
a.MuiButton-text.Basicactive.small-basic-btn, a.MuiButton-text.Advancedactive.small-basic-btn, a.MuiButton-text.Proactive.small-basic-btn  { padding: 0 3px; margin-top: 4px; line-height: 22px; font-size: 13px; }

.tabs-business-inner a.Advancedactive { background: #336601; }
.tabs-business-inner a.Proactive { background: #0C4C72; }
.business-text-inner-cstm { padding: 0px; }
.business-text-inner-cstm button.valueFirst { background: #F4DEB9; color: #000; width: 100%; font-weight: 600; }
.business-text-inner-cstm button.valueSecond { background: #C1D2B2; color: #000; width: 100%; font-weight: 600; }
.business-text-inner-cstm button.valueThird { background: #B7C9D5; color: #000; width: 100%; font-weight: 600; }
svg.MuiSvgIcon-root.success-box { color: #327009; }
svg.MuiSvgIcon-root.cancel-box { color: #E01C1B; }
td.business-text-inner-cstm { padding: 2px; }
.MuiGrid-root.fullwidthnew.newTableBox th { font-size: 15px; font-weight: 600; padding: 20px 10px; }
.MuiTableRow-root.table-check th { padding: 0px !important; }
.MuiTableRow-root.table-check td.business-text-inner-cstm { padding: 2px !important; }
.MuiGrid-root.ButtonBottom { float: left; width: 100%; margin-top: 175px; margin-bottom: 10px; }
a.MuiButtonBase-root.FreePricingbtn { background: #2B2B2B; color: #fff; font-size: 17px; padding: 6px 16px; font-weight: 600; margin-left: -10px; }



/* 04/02/2023 */
.MuiFormControl-root.cstm-select.price-type-box { min-width: 150px; margin-top: 0 !important; float: left; margin-right: 24px; }
.MuiFormControl-root.cstm-select.price-type-box .MuiOutlinedInput-input { padding: 15.5px 14px; }



/* 06/02/2023 */
// .MuiGrid-root.box-size.fullWidth.copyclipboardbox.padQR input { padding-right: 55px; }



/* 20/02/2023 */
.MuiFormControl-root.cstm-select.colorActive.MuiFormControl-fullWidth { float: left; width: auto; margin: 0 0 0 10px !important; }
.MuiFormControl-root.cstm-select.colorActive.MuiFormControl-fullWidth .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input { font-family: "geomanistregular" !important; font-weight: 600; background: #6A04A9; color: #fff; font-size: 15px; padding: 15px 25px 15px 15px; border-radius: 4px; }
.MuiFormControl-root.cstm-select.colorActive.MuiFormControl-fullWidth .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline { border: none !important; }
.MuiGrid-root.btn-box-right { float: right; }



/* 21/02/2023 */
.scroll-tb { max-width: 100%; overflow: auto !important; }
.MuiTableContainer-root.simple-table.mtt-0.scroll-tb.large-tb table.MuiTable-root {
	min-width: 630px;
	width: 100%;
}



/* 27/02/2023 */
p.MuiTypography-root.cashtitle {
	text-align: center;
	font-size: 29px;
	text-transform: uppercase;
	color: #dc9018;
	font-weight: 600;
	margin-bottom: 5px;
}
p.MuiTypography-root.cashdes {
	text-align: center;
	font-size: 16px;
	max-width: 325px;
	margin: 0 auto;
}
.MuiGrid-root.DepositWithdrawalBox {
	margin: 14px auto 30px;
	display: table;
}



/* 09/03/2023 */
span.MuiTypography-root.fulltext {
	float: left;
	width: 100%;
	margin-bottom: 10px;
	word-break: break-all;
}




/* 03/04/2023 */
.MuiGrid-root.new-main-tab.firstbox-tabs .box-select .MuiInputBase-root.MuiOutlinedInput-root {
	padding-right: 30px;
}
.MuiGrid-root.new-main-tab.firstbox-tabs .box-select .MuiInputBase-root.MuiOutlinedInput-root input {
	background: transparent !important;
	color: #fff;
    padding: 10px 14px;
}
.MuiGrid-root.new-main-tab.firstbox-tabs .box-select .MuiInputBase-root.MuiOutlinedInput-root .MuiAutocomplete-clearIndicator { color: #fff;}
.MuiGrid-root.new-main-tab.firstbox-tabs .box-select.iconbox svg { color: #fff;}
.MuiGrid-root.new-main-tab.secondbox-tabs .box-select .MuiInputBase-root.MuiOutlinedInput-root {
	padding-right: 30px;
}
.MuiGrid-root.new-main-tab.secondbox-tabs .box-select .MuiInputBase-root.MuiOutlinedInput-root input {
	background: transparent !important;
	color: #fff;
    padding: 10px 14px;
}
.MuiGrid-root.new-main-tab.secondbox-tabs .box-select .MuiInputBase-root.MuiOutlinedInput-root .MuiAutocomplete-clearIndicator { color: #fff;}
.MuiGrid-root.new-main-tab.secondbox-tabs .box-select.iconbox svg { color: #fff;}



// 07-04-2023
thead tr.MuiTableRow-root {
	background-color: #fff !important;
}



// 10-04-2023
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-user.active-btn.homelang { background: no-repeat; border: none; margin: 0; padding: 0; min-width: auto; }
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-user.active-btn.homelang { background: no-repeat; margin: 0; padding: 0; border: none; }
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-user.active-btn.homelang .MuiSelect-select { padding: 0; background: transparent !important; }
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-user.active-btn.homelang .MuiSelect-select img.flag { border-radius: 4px ; width: 31px; float: left; height: 20px; margin-right: 3px; }
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-user.active-btn.homelang .MuiSelect-select img.flag-2 { border-radius: 4px ; width: 38px; float: left; height: 30px; }
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-user.active-btn.homelang fieldset { border: none; }
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-user.active-btn.homelang svg { display: none; }



// 26-05-2023
.cstm-2fa .MuiSwitch-switchBase { left: 4px !important; }
.cstm-2fa .MuiSwitch-switchBase.Mui-checked span.MuiSwitch-thumb { background: white; width: 12px; height: 12px; top: 4px; }
.cstm-2fa .MuiSwitch-switchBase.Mui-checked+span.MuiSwitch-track { background: #73B13B !important; opacity: 1 !important; }


.cstm-2fa .MuiSwitch-switchBase span.MuiSwitch-thumb { background: white; width: 12px; height: 12px; margin-top: 4px; }
.cstm-2fa .MuiSwitch-switchBase+span.MuiSwitch-track { background: red !important; opacity: 1 !important; }

.MuiButton-root.MuiButton-text.get-started-btn.continue-cstm-tabs-btn.btnVoucher { background: #3C1360 !important; font-weight: 700; text-transform: uppercase !important; }
.MuiButton-root.MuiButton-text.get-started-btn.continue-cstm-tabs-btn.btnVoucher span { text-transform: uppercase; }
.MuiDialog-root.dialogs-msg-popup .danger-blue { color: #0000FF; font-size: 19px; text-align: center; margin-top: 20px; font-weight: 500; }

// 02-10-2023
svg.MuiSvgIcon-root.menu-mobie { color: #fff; font-size: 28px; position: absolute; right: 12px; top: 15px; display: none; }

@media (max-width: 960px) {
	svg.MuiSvgIcon-root.menu-mobie  { display: block; }
}


@media (min-width: 1223px) and (max-width:1283px) {
	.MuiGrid-root.menu-link link { margin: 0 6px; }
}

.MuiGrid-root.mobile-login-btn { display: none; }

@media (max-width: 767px) {
	.header-space { padding-left: 5px !important; padding-right: 5px !important; }
	.MuiGrid-root.logo-box { width: auto !important; }
	.MuiGrid-root.login-box.MuiGrid-item { float: right; margin: 3px 0px 0px auto !important; }
	a.btn-user.none-mobile { display: none; }
	.MuiGrid-root.pull-right.top-space { margin-top: 11px; }
	.MuiGrid-root.mobile-login-btn { float: none; width: auto; margin: 14px auto 5px; display: table; }
	.MuiGrid-root.mb-fullWidth { float: left; width: 100%; }
	.MuiButton-text.btn-user { margin: 0 5px; }
	button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-user.active-btn.homelang.mobile-flag-icon img { height: 30px !important; width: 40px !important; }
	button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-user.active-btn.homelang.mobile-flag-icon + .MuiGrid-root.light-mode button svg { font-size: 27px; float: right; margin-top: -2px; margin-left: 5px; }
}

@media (max-width: 550px) {
	.MuiGrid-root.agreeCheckbox.captcha iframe { transform:scale(0.70);-webkit-transform:scale(0.70);transform-origin:0 0;-webkit-transform-origin:0 0; }
	.MuiGrid-root.agreeCheckbox.captcha > div > div > div { width: 212px !important;}
}


/* 25/07/2023 */
.mobileQR {  width: 30px; height: 30px; margin-right: 15px; background: #3C1360; padding: 5px; border-radius: 3px; display: none; }
.qrGenerator .MuiPaper-root.MuiDialog-paperWidthSm { min-width: 320px; }
.qrGenerator .MuiPaper-root.MuiDialog-paperWidthSm h2 { font-size: 22px; text-align: center; text-shadow: none; font-weight: bold !important; margin-top: 13px; }
.qrGenerator .MuiGrid-root.new-main-tab.sub.bg-dark-new-tabs input { font-size: 16px;}
button.continue-modal-btn span { text-transform: uppercase; font-weight: 600 !important; }
button.continue-modal-btn { border-radius: 29px; margin: 14px 3px 20px 3px; background: #F28A11; padding: 8px 15px; font-size: 18px; font-weight: 500; color: #fff; text-align: center; min-width: 140px; text-transform: uppercase; }
button.continue-modal-btn.active { background: #3C1360; }
svg.MuiSvgIcon-root.close-icon { position: absolute; right: 6px; top: 6px; cursor: pointer; }
.QRCode { width: 100%; max-width: 260px; margin: 0 auto 12px; display: table; }


// .desktopModal .MuiDialog-container { height: auto; right: 0; justify-content: end; }
.desktopQR {  width: 37px; margin-right: 15px;  border-radius: 10px 0 0 10px !important; background: #3C1360; padding: 7px; border-radius: 3px; }
button.desktop-QR { position: fixed !important; }
// .desktopModal .MuiPaper-root.MuiDialog-paperWidthSm { right: -32px !important; top: 97px; border-radius: 10px 0 0 10px; }
button.desktop-QR { position: absolute; right: -24px; margin-top: 130px; padding: 0; z-index: 1; }
button.desktop-QR span { margin: 0px; padding: 0px; }
button.desktop-QR:hover { background: none !important; }

@media (max-width: 991px) {
	button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-user.active-btn.homelang.mobile-flag-icon img.mobileQR { width: 30px !important; height: 30px !important; }
	.mobileQR { display: block !important;}
	.desktopQR { display: none; }
	.qrGenerator .MuiDialogContent-root { padding-left: 14px; padding-right: 14px; }
}



/* 25/09/2023 */
.cstm-video-iframe iframe { margin: 0; width: 100%; }
.MuiGrid-root.mobile-inner-pad.atm_Details_new.fullWidth { float: left; width: 100%; }
.MuiTypography-root.day-list.FullWidth { width: 100%; }
.MuiGrid-root.new-main-tab.secondbox-tabs .box-select { width: 100% }



/* 13/12/2023 */
.MuiGrid-root.new-main-tab.firstbox-tabs.noneicon .MuiGrid-root.firstbox .MuiGrid-root.money-select.money_crypto .MuiInputBase-fullWidth { padding-right: 0px; }
.MuiGrid-root.new-main-tab.firstbox-tabs.noneicon .MuiGrid-root.firstbox .MuiGrid-root.money-select.money_crypto .MuiInputBase-fullWidth .MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined { padding-right: 30px; }
.MuiGrid-root.new-main-tab.secondbox-tabs.noneicon .MuiGrid-root.firstbox .MuiGrid-root.money-select.money_crypto .MuiInputBase-fullWidth { padding-right: 0px; }
.MuiGrid-root.new-main-tab.secondbox-tabs.noneicon .MuiGrid-root.firstbox .MuiGrid-root.money-select.money_crypto .MuiInputBase-fullWidth .MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined { padding-right: 30px; }